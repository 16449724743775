import { orderBy, uniqBy } from "lodash";
import moment from "moment";

export function secondsToHoursAndMinutes(s: number): string {
  if (s === null) return "0";
  s = Number(s);
  var h = Math.floor(s / 3600);
  var m = Math.floor((s % 3600) / 60);
  s = Math.floor((s % 3600) % 60);
  var hDisplay = h > 0 ? h + (h === 1 ? "h " : "h ") : "";
  var mDisplay = m > 0 ? m + (m === 1 ? "m " : "m ") : "";
  var sDisplay = s > 0 ? s + (s === 1 ? "s" : "s") : "";
  if (hDisplay !== "" || mDisplay !== "") {
    return hDisplay + mDisplay;
  } else {
    return sDisplay;
  }
}

export const enumerateDaysBetweenDates = function (
  startDate: any,
  endDate: any
) {
  var dates = [];
  var currDate = moment(startDate).subtract(1, "day").startOf("day");
  var lastDate = moment(endDate).add(1, "day").startOf("day");
  while (currDate.add(1, "days").diff(lastDate) < 0)
    dates.push({ x: currDate.valueOf(), y: 0 });
  return dates;
};

export const prepareChartData = (data: any, dates: any) => {
  data = data?.map((d: any) => {
    return {
      x: moment(d.x).startOf("day").valueOf(),
      y: d.y,
    };
  });
  let merged = [...data, ...dates];
  merged = uniqBy(merged, "x");
  let final = orderBy(merged, ["x"], ["asc"]);
  return final;
};

export function calculateProgress(finishedUnits: number, totalUnits: number) {
  if(finishedUnits && totalUnits) return (finishedUnits / totalUnits) * 100
  return 0;
}
