import { useState } from "react";
import MFAInput from "../../components/input/MFAInput";
import { SubmitHandler, useForm } from "react-hook-form";
import useAxios from "axios-hooks";
import { AuthLink } from "./components/AuthLink";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

type Inputs = {
  code: number;
};

export default function LoginMFA() {

  const navigate = useNavigate();
  
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const [{ data, loading, error }, loginMFA] = useAxios(
    {
      url: "/auth/login/mfa",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      let response = await loginMFA({
        data: data,
      });
      if(response.status === 200) {
        navigate('/members');
      }
    } catch (error) {}
  };

  return (
    <div className="flex items-center justify-center flex-col h-full gap-4">
      <div className="alert text-sm w-1/2">
        <ExclamationCircleIcon className="h-5"></ExclamationCircleIcon>
        Your account is secured with Multi-Factor Authentication. Please provide
        your MFA code below.
      </div>
      <form className="flex flex-col w-1/2" onSubmit={handleSubmit(onSubmit)}>
        <label className="form-control w-full">
          <div className="label">
            <span className="label-text">Code</span>
          </div>
          <input
            disabled={loading}
            {...register("code", {
              required: true,
              maxLength: 6,
              minLength: 6,
            })}
            className="input input-bordered input-sm w-full text-center font-bold"
          />
          <div className="label">
            {errors?.code?.type === "required" && (
              <span className="label-text-alt text-error">
                This field is required
              </span>
            )}
            {(errors?.code?.type === "maxLength" || errors?.code?.type === "minLength") && (
              <span className="label-text-alt text-error">
                Incorrect code length
              </span>
            )}
          </div>
        </label>
        <div className="pt-2 w-full">
          <button
            className="btn btn-sm w-full btn-primary"
            disabled={loading}
            type="submit"
          >
            Login
          </button>
        </div>

        {error && (
          <div className="alert alert-error text-sm mt-6 text-center">
            {error?.response?.data?.message}
          </div>
        )}

        {loading && (
          <div className="flex items-center justify-center mt-6">
            <div className="loading loading-spinner "></div>
          </div>
        )}
      </form>

      <div className="flex flex-col gap-2 items-center">
        <AuthLink
          label="Recover MFA"
          message="Lost MFA? Use recovery key."
          path="/auth/mfa/recover"
        ></AuthLink>
      </div>
    </div>
  );
}
