import { SubmitHandler, useForm } from "react-hook-form";
import { AuthLink } from "./components/AuthLink";
import { useNavigate } from "react-router-dom";
import useAxios from "axios-hooks";
import { CheckIcon } from "@heroicons/react/24/outline";

type Inputs = {
  email: string;
  username: string;
  password: string;
};

export default function SignUp() {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const [{ data, loading, error }, login] = useAxios(
    {
      url: "/auth/signup",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      let response = await login({
        data: {
          ...data,
          accountType: "BUSINESS",
        },
      });
    } catch (error) {}
  };

  return (
    <div className="flex items-center justify-center h-full flex-col gap-4">
      <form className="flex flex-col w-1/2" onSubmit={handleSubmit(onSubmit)}>
        <label className="form-control w-full">
          <div className="label">
            <span className="label-text">Email</span>
          </div>
          <input
            disabled={loading}
            {...register("email", {
              required: true,
              pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i,
            })}
            type="text"
            className="input input-bordered input-sm w-full"
          />
          <div className="label">
            {errors?.email?.type === "required" && (
              <span className="label-text-alt text-error">
                This field is required
              </span>
            )}
            {errors?.email?.type === "pattern" && (
              <span className="label-text-alt text-error">
                Please provide a valid email address
              </span>
            )}
          </div>

          <div className="label">
            <span className="label-text">Username</span>
          </div>
          <input
            disabled={loading}
            {...register("username", {
              required: true,
            })}
            type="text"
            className="input input-bordered input-sm w-full"
          />
          <div className="label">
            {errors?.username?.type === "required" && (
              <span className="label-text-alt text-error">
                This field is required
              </span>
            )}
          </div>

          <div className="label">
            <span className="label-text">Password</span>
          </div>
          <input
            disabled={loading}
            {...register("password", { required: true })}
            type="text"
            className="input input-bordered input-sm w-full"
          />
          <div className="label">
            {errors?.password?.type === "required" && (
              <span className="label-text-alt text-error">
                This field is required
              </span>
            )}
          </div>

          <div className="label">
            <span className="label-text">Confirm Password</span>
          </div>
          <input
            disabled={loading}
            {...register("password", { required: true })}
            type="text"
            className="input input-bordered input-sm w-full"
          />
          <div className="label">
            {errors?.password?.type === "required" && (
              <span className="label-text-alt text-error">
                This field is required
              </span>
            )}
          </div>
        </label>
        <div className="pt-2 w-full">
          <button
            disabled={loading}
            className="btn btn-sm w-full btn-primary"
            type="submit"
          >
            Sign Up
          </button>
        </div>

        {error && (
          <div className="alert alert-error text-sm mt-6">
            {error.response?.data?.message}
          </div>
        )}
        {loading && (
          <div className="flex items-center justify-center mt-6">
            <div className="loading loading-spinner "></div>
          </div>
        )}
        {data && (
          <div className="alert alert-success text-sm mt-6">
            <CheckIcon></CheckIcon>
            Registration completed successfully! Please verify your email
            address.
          </div>
        )}
      </form>
      <div className="flex flex-col gap-2 items-center">
        <AuthLink
          label="Sign In"
          message="You already have an account?"
          path="/auth/login"
        ></AuthLink>
      </div>
    </div>
  );
}
