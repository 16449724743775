import { useContext, useEffect, useState } from "react";
import {
  PaymentMethod,
  StripePaymentConfirmation,
} from "../../components/stripe/PaymentMethod";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  InformationCircleIcon,
  MinusIcon,
  PencilIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import AccountInfo, { AccountInfoForm } from "./AccountInfo";
import useAxios from "axios-hooks";
import {
  BusinessResDto,
  CreatePaymentDto,
  PaymentResponse,
} from "../../api/Api";
import PaymentSVG from "./PaymentSVG";
import LoginSVG from "../auth/LoginSVG";
import { useNavigate } from "react-router-dom";
import CountedRedirect from "../../components/navigation/CountedRedirect";
import { UserContext } from "../../App";
import UserSelectionModal from "../../components/user-selection/UserSelectionModal";
import axios from "../../api/axios-conf";
import ThemeToggler from "../../components/input/ThemeToggler";

export default function AccountSubscription(props: {}) {
  // if (businessInfoLoading) return <h1>Loading...</h1>;

  return (
    <div className="flex flex-col gap-2">
      <div className="text-lg font-bold">Subscription</div>
      <AccountSubscriptionUpdate></AccountSubscriptionUpdate>
      <div className="text-lg font-bold">Card</div>
      <PaymentMethodUpdate></PaymentMethodUpdate>
    </div>
  );
}

function AccountSubscriptionUpdate() {
  const [isEdit, setIsEdit] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [paymentIntentClientSecret, setPaymentIntentClientSecret] = useState();
  const [paymentStatus, setPaymentStatus] = useState(undefined as any);
  const [activeMembers, setActiveMembers] = useState(undefined as any);

  const [quantity, setQuantity] = useState(0);

  const [
    {
      data: subscription,
      loading: subscriptionLoading,
      error: subscriptionError,
    },
    getSubscriptionInfo,
  ] = useAxios({
    url: "/payments/subscription",
    method: "GET",
  });

  const [
    {
      data: updateSubscriptionData,
      loading: updateSubscriptionLoading,
      error: updateSubscriptionError,
    },
    updateSubscription,
  ] = useAxios(
    {
      url: "/payments/subscription",
      method: "PATCH",
    },
    {
      manual: true,
    }
  );

  const [
    {
      data: cancelSubscriptionUpdateData,
      loading: cancelSubscriptionUpdateLoading,
      error: cancelSubscriptionUpdateError,
    },
    cancelSubscriptionUpdate,
  ] = useAxios(
    {
      url: "/payments/subscription/cancelUpdate",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const [
    {
      data: retryPaymentData,
      loading: retryPaymentLoading,
      error: retryPaymentError,
    },
    retryPayment,
  ] = useAxios(
    {
      url: "/payments/subscription/retryPayment",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const on3DSecurePaymentConfirmation = async (response: any) => {
    const paymentStatus = response?.paymentIntent?.status;
    if (paymentStatus === "succeeded") {
      setPaymentStatus("succeeded");
    } else {
      setPaymentStatus("subscription_update_failed");
    }
    setPaymentIntentClientSecret(undefined);
  };

  useEffect(() => {
    if (!subscriptionLoading && subscription) {
      const latestInvoice = subscription?.latest_invoice;
      const paymentIntent = latestInvoice?.payment_intent;

      if (
        [
          "requires_action",
          "requires_confirmation",
          "requires_payment_method",
        ].includes(paymentIntent?.status)
      ) {
        setIsUpdating(true);
        setPaymentStatus(
          latestInvoice.billing_reason === "subscription_update"
            ? "subscription_update_failed"
            : "subscription_cycle_failed"
        );
      }

      setQuantity(subscription?.quantity as number);
      setActiveMembers(subscription?.total_active_members);
      // setQuantity(1);
    }
  }, [subscriptionLoading]);

  const handleUpdateSubscription = async () => {
    try {
      setIsUpdating(true);
      let response = await updateSubscription({
        data: {
          quantity: quantity,
        },
      });
      if (response?.data?.latest_invoice?.paid) {
        // Is Downgrade
        setPaymentStatus("succeeded");
        return;
      }

      setPaymentStatus(response?.data?.latest_invoice?.payment_intent?.status);
      if (
        response?.data?.latest_invoice?.payment_intent?.status ===
        "requires_action"
      ) {
        setPaymentIntentClientSecret(
          response?.data?.latest_invoice?.payment_intent?.client_secret
        );
      }
    } catch (error) {}
  };

  const handleCancelSubscriptionUpdate = async () => {
    try {
      setPaymentStatus("canceling_update");

      const response = await cancelSubscriptionUpdate();

      setPaymentStatus("update_canceled");
    } catch (error) {}
  };

  const handleRetryPayment = async () => {
    try {
      setPaymentIntentClientSecret(undefined);
      setPaymentStatus("retrying_payment");

      const response = await retryPayment();

      if (
        response.data.status === "requires_action" ||
        response.data.status === "requires_confirmation"
      ) {
        setPaymentIntentClientSecret(response?.data?.client_secret);
      }

      setPaymentStatus(response?.data?.status);
    } catch (error) {
      setPaymentStatus("subscription_update_failed");
    }
  };

  const handleMemberDisabled = async () => {
    try {
      const response = await axios.get("/payments/subscription");
      setActiveMembers(response.data.total_active_members);
    } catch (error) {}
  };

  if (subscriptionLoading) return <h1>Loading...</h1>;

  if (isUpdating)
    return (
      <div className="card bg-base-200">
        <div className="card-body">
          {updateSubscriptionLoading && (
            <div className="flex items center justify-center">
              <div className="flex flex-col items-center justify-center gap-2">
                <div className="text-center text-lg font-bold">
                  Updating Subscription! Please wait...
                </div>
                <span className="loading loading-spinner"></span>
              </div>
            </div>
          )}
          {paymentStatus === "requires_action" && (
            <div className="pl-10 pr-10">
              <div className="alert alert-warning text-sm ">
                Your card is secured with 3D Secure. Please await the appearance
                of the payment confirmation pop-up.{" "}
                <span className="loading loading-spinner"></span>
              </div>
            </div>
          )}
          {paymentStatus === "requires_confirmation" && (
            <div className="pl-10 pr-10">
              <div className="alert alert-warning text-sm ">
                Confirming the transaction
                <span className="loading loading-spinner"></span>
              </div>
            </div>
          )}
          {paymentStatus === "succeeded" && (
            <div className="flex flex-col gap-2 items-center justify-center">
              <div className="alert alert-success text-sm ">
                Payment proccessed successfully and the new subscription is
                active.
              </div>
              <button
                onClick={() => {
                  setIsEdit(false);
                  setIsUpdating(false);
                  getSubscriptionInfo();
                }}
                className="btn btn-sm  btn-primary"
              >
                Close
              </button>
            </div>
          )}

          {paymentStatus === "subscription_update_failed" && (
            <div className="flex flex-col gap-4">
              <div className="alert alert-error text-sm ">
                We attempted to process your subscription update, but
                encountered an issue charging your card. You have the option to
                either cancel the update or provide updated card information to
                retry the transaction.
              </div>
              <div className="flex gap-5 items-center justify-center">
                <button
                  onClick={handleRetryPayment}
                  className="btn btn-sm text-primary btn-ghost"
                >
                  RETRY UPDATE
                </button>
                <button
                  onClick={handleCancelSubscriptionUpdate}
                  className="btn btn-sm text-primary btn-ghost"
                >
                  CANCEL UPDATE
                </button>
              </div>
            </div>
          )}

          {paymentStatus === "subscription_cycle_failed" && (
            <div className="flex flex-col gap-4">
              <div className="alert alert-error text-sm ">
                We attempted to process your subscription cycle, but encountered
                an issue charging your card. You have the option to provide
                updated card information and retry the transaction.
              </div>
              <div className="flex gap-5 items-center justify-center">
                <button
                  onClick={handleRetryPayment}
                  className="btn btn-sm text-primary btn-ghost"
                >
                  RETRY PAYMENT
                </button>
              </div>
            </div>
          )}

          {paymentStatus === "retrying_payment" && (
            <div className="">
              <div className="alert alert-warning text-sm ">
                Retrying failed payment. Please wait
                <span className="loading loading-spinner"></span>
              </div>
            </div>
          )}

          {paymentStatus === "canceling_update" && (
            <div className="">
              <div className="alert alert-warning text-sm ">
                Canceling your update request. Please wait
                <span className="loading loading-spinner"></span>
              </div>
            </div>
          )}

          {paymentStatus === "update_canceled" && (
            <div className="flex flex-col gap-4 items-center justify-center">
              <div className="alert alert-success text-sm ">
                Your subscription update was canceled successfully. You may
                proceed using your current subscription.
              </div>
              <button
                onClick={() => {
                  setIsEdit(false);
                  setIsUpdating(false);
                  setQuantity(subscription?.quantity);
                }}
                className="btn btn-sm  btn-ghost text-primary"
              >
                CLOSE
              </button>
            </div>
          )}

          {paymentIntentClientSecret && (
            <StripePaymentConfirmation
              secret={paymentIntentClientSecret}
              onComplete={on3DSecurePaymentConfirmation}
            ></StripePaymentConfirmation>
          )}
        </div>
      </div>
    );

  if (isEdit) {
    return (
      <div className="card bg-base-200">
        <div className="card-body">
          <div className="flex">
            <div className="text-lg font-bold">Update</div>
            <button
              onClick={() => {
                setIsEdit(false);
                setQuantity(subscription?.quantity);
              }}
              className="btn btn-circle btn-sm ml-auto btn-ghost text-primary"
            >
              <XMarkIcon></XMarkIcon>
            </button>
          </div>
          <div className="flex items-center justify-center">
            <div className="flex flex-col gap-6 w-1/2 ">
              <div className="flex flex-col gap-4  ">
                <div className="text-lg font-bold">Number of Users</div>
                <div className="w-full">
                  <UserQuantityCounter
                    quantity={quantity}
                    onChange={setQuantity}
                  ></UserQuantityCounter>
                </div>
              </div>
              <div className="divider  m-0"></div>
              <div className="text-lg font-bold">Billing Information</div>
              <div className={`flex gap-6 items-center justify-between`}>
                <div className="flex flex-col gap-1 bg-base-300 p-4 rounded-xl w-full">
                  <div className="text-md font-bold">Current Billing</div>
                  <div className=" text-md opacity-75 ">$50 per user</div>
                  <div className=" text-md opacity-75">
                    {subscription?.quantity} users * $50/month
                  </div>
                  <div className="divider m-0"></div>
                  <div className="flex">
                    <div className=" text-lg  font-bold">
                      ${subscription?.quantity * 50}/month
                    </div>
                  </div>
                </div>
                {quantity >= 2 && subscription?.quantity !== quantity && (
                  <>
                    <div className="flex flex-col gap-1 bg-base-300 p-4 rounded-xl w-full">
                      <div className="text-md font-bold">New Users</div>
                      <div className=" text-md opacity-75 ">$50 per user</div>
                      <div className=" text-md opacity-75">
                        {quantity - subscription?.quantity} users * $50/month
                      </div>
                      <div className="divider m-0"></div>
                      <div className="flex">
                        <div className=" text-lg font-bold">
                          ${(quantity - subscription?.quantity) * 50}
                          /month
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col gap-1 bg-primary p-4 rounded-xl w-full ">
                      <div className="text-md font-bold">Total New Billing</div>
                      <div className=" text-md  ">$50 per user</div>
                      <div className=" text-md ">
                        {quantity} users * $50/month
                      </div>
                      <div className="divider m-0"></div>
                      <div className="flex">
                        <div className=" text-lg  font-bold ">
                          ${quantity * 50}/month
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

              {quantity >= 2 && subscription?.quantity > quantity && (
                <>
                  <div className="divider m-0"></div>
                  <div className="text-lg font-bold">
                    Subscription Downgrade
                  </div>
                  <>
                    <div className="alert bg-base-300 ">
                      <InformationCircleIcon className="h-6"></InformationCircleIcon>
                      <div className="italic text-sm ">
                        Your subscription will update with the new quantity. You
                        will be reimburesed for the unused remaning time of{" "}
                        <span className="text-primary">
                          {subscription?.quantity - quantity}
                        </span>{" "}
                        memeber(s) on the upcoming billing cycle.
                      </div>
                    </div>
                  </>

                  {subscription?.quantity - activeMembers <
                    subscription?.quantity - quantity && (
                    <>
                      <div className="alert bg-base-300 ">
                        <InformationCircleIcon className="h-6"></InformationCircleIcon>
                        <div className="italic text-sm ">
                          To adjust your subscription by reducing the number of
                          members, you must designate which members you intend
                          to disable. The unused time of the memebers you
                          disable now will be reimburse on the next subscription
                          cycle. Currently, you have to select{" "}
                          <span className="text-primary">
                            {subscription?.quantity -
                              quantity -
                              (subscription?.quantity - activeMembers)}
                          </span>{" "}
                          member(s) to disable.
                        </div>
                      </div>

                      <div className="flex items-center justify-center">
                        <UserSelectionModal
                          requiredDisableCount={
                            subscription?.quantity -
                            quantity -
                            (subscription?.quantity - activeMembers)
                          }
                          onComplete={() => {
                            handleMemberDisabled();
                          }}
                        ></UserSelectionModal>
                      </div>
                    </>
                  )}
                </>
              )}
              {quantity >= 2 &&
                subscription?.quantity !== quantity &&
                !(
                  subscription?.quantity - activeMembers <
                  subscription?.quantity - quantity
                ) && (
                  <>
                    <div className="divider"></div>
                    <div className="flex items-center justify-center">
                      <button
                        onClick={handleUpdateSubscription}
                        className="btn btn-primary"
                      >
                        Update Subscription
                      </button>
                    </div>
                  </>
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="card bg-base-200">
      <div className="card-body">
        <div className="flex items-center">
          <div className="flex flex-col gap-2">
            <div className="font-bold text-center">
              <span className="text-primary">{subscription?.quantity}</span>{" "}
              active users * $50/month = {""}
              <span className="text-primary">
                ${(subscription?.quantity as number) * 50}/month
              </span>
            </div>
            <div className="font-bold text-sm italic">
              <span className="opacity-80">
                Ends on:{" "}
                {new Date(
                  subscription?.current_period_end * 1000
                ).toDateString()}
              </span>
            </div>
          </div>
          <button
            onClick={() => setIsEdit(true)}
            className="btn ml-auto btn-primary btn-sm btn-ghost text-primary"
          >
            Edit <PencilIcon className="h-4"></PencilIcon>
          </button>
        </div>
      </div>
    </div>
  );
}

function PaymentMethodUpdate() {
  const [isEdit, setIsEdit] = useState(false);

  const [
    {
      data: defaultPaymentMethod,
      loading: defaultPaymentMethodLoading,
      error: defaultPaymentMethodError,
    },
    getDefaultPaymentMethod,
  ] = useAxios({
    url: "/payments/defaultPaymentMethod",
    method: "GET",
  });

  const [
    {
      data: defaultPaymentMethodUpdate,
      loading: defaultPaymentMethodUpdateLoading,
      error: defaultPaymentMethodUpdateError,
    },
    updateDefaultPaymentMethod,
  ] = useAxios(
    {
      url: "/payments/defaultPaymentMethod",
      method: "PATCH",
    },
    {
      manual: true,
    }
  );

  const handleUpdateDefaultMethod = async (paymentMethod: any) => {
    try {
      await updateDefaultPaymentMethod({
        data: {
          paymentMethodId: paymentMethod?.paymentMethod?.id,
        },
      });
      setIsEdit(false);
      getDefaultPaymentMethod();
    } catch (error) {}
  };

  if (defaultPaymentMethodLoading) return <h1>Loading...</h1>;

  if (isEdit) {
    return (
      <div className="card bg-base-200">
        <div className="card-body ">
          <div className="flex">
            <button
              onClick={() => {
                setIsEdit(false);
              }}
              className="btn btn-circle btn-sm ml-auto btn-ghost text-primary"
            >
              <XMarkIcon></XMarkIcon>
            </button>
          </div>
          <div className="flex items-center justify-center">
            {defaultPaymentMethodUpdateLoading ? (
              <h1>Updating payment method. Please wait...</h1>
            ) : (
              <div className="w-1/3">
                <PaymentMethod
                  onComplete={handleUpdateDefaultMethod}
                ></PaymentMethod>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="card bg-base-200">
      <div className="card-body">
        <div className="flex items-center gap-2">
          <img
            className="h-12"
            src={`/cards/${defaultPaymentMethod?.card?.brand}.svg`}
          ></img>
          <div className="flex flex-col">
            <div className="font-bold opacity-75">
              **** **** **** {defaultPaymentMethod?.card?.last4}
            </div>
            <div className="opacity-75 font-bold">
              Expiring in: {defaultPaymentMethod?.card?.exp_month}/
              {defaultPaymentMethod?.card?.exp_year}
            </div>
          </div>
          <button
            onClick={() => setIsEdit(true)}
            className="btn ml-auto btn-primary btn-sm btn-ghost text-primary"
          >
            Edit <PencilIcon className="h-4"></PencilIcon>
          </button>
        </div>
      </div>
    </div>
  );
}

export function AccountIntialSubscriptionView() {
  return (
    <div className="w-screen h-screen">
      <div className="flex w-full h-full">
        <div className="w-[50%] h-full ">
          <AccountInitalSubscription></AccountInitalSubscription>
        </div>
        <div className="w-[50%] h-full ">
          <div className="flex flex-col h-full">
            <div className="ml-auto p-4">
              <ThemeToggler></ThemeToggler>
            </div>
            <div className=" h-full flex items-center justify-center ">
              <p className="font-bold text-4xl">
                <span className="text-primary">Socora</span>{" "}
                <span>Business</span>
                <p className="text-sm">Account Setup</p>
              </p>
            </div>

            <div className="flex items-end justify-end">
              <LoginSVG></LoginSVG>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function AccountInitalSubscription() {
  const navigate = useNavigate();

  const [quantity, setQuantity] = useState(() => {
    let quantity = localStorage.getItem("quantity") as string;
    return quantity ? parseInt(quantity) : 1;
  });

  const [paymentIntentClientSecret, setPaymentIntentClientSecret] = useState();
  const [subscriptionStatus, setSubscriptionStatus] = useState(
    undefined as any
  );

  const [paymentMethod, setPaymentMethod] = useState(() => {
    let paymentMethod = localStorage.getItem("paymentMethod");
    return paymentMethod ? JSON.parse(paymentMethod) : undefined;
  });

  const [currentStep, setCurrentStep] = useState(() => {
    let currentStep = localStorage.getItem("currentStep");
    return currentStep ? parseInt(currentStep) : 0;
  });

  const [
    {
      data: businessInfo,
      loading: businessInfoLoading,
      error: businessInfoError,
    },
    getBusinessInfo,
  ] = useAxios<BusinessResDto>({
    url: "/business",
    method: "GET",
  });

  const [
    {
      data: subscriptionData,
      loading: subscriptionLoading,
      error: subscriptionError,
    },
    subscribe,
  ] = useAxios<any, CreatePaymentDto>(
    {
      url: "/payments/subscription",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const onPaymentMethod = (paymentMethod: any) => {
    setPaymentMethod(paymentMethod);
    localStorage.setItem("paymentMethod", JSON.stringify(paymentMethod));
  };

  const onQuantityChange = (newQuantity: number) => {
    setQuantity(newQuantity);
    localStorage.setItem("quantity", newQuantity.toString());
  };

  const onSubscribe = async () => {
    try {
      setPaymentIntentClientSecret(undefined);
      setSubscriptionStatus(undefined);

      let subscription = await subscribe({
        data: {
          paymentMethodId: paymentMethod?.paymentMethod.id,
          quantity: quantity,
        },
      });

      const paymentStatus =
        subscription?.data?.latest_invoice?.payment_intent?.status;

      if (paymentStatus === "succeeded") {
        setSubscriptionStatus("succeeded");
        return;
      }

      // Payment requeires action. 3D Secure payment.
      if (paymentStatus === "requires_action") {
        const paymentClientSecret =
          subscription?.data?.latest_invoice?.payment_intent?.client_secret;
        setPaymentIntentClientSecret(paymentClientSecret);
        setSubscriptionStatus("requires_action");
      }
    } catch (error) {}
  };

  const on3DSecurePaymentConfirmation = async (response: any) => {
    const paymentStatus = response?.paymentIntent?.status;

    if (paymentStatus === "succeeded") {
      setSubscriptionStatus("succeeded");
    } else {
      setSubscriptionStatus("generic_failure");
    }
  };

  const canMoveToNextStep = () => {
    if (currentStep === 0 && businessInfo) return true;
    if (currentStep === 1 && paymentMethod) return true;
    if (currentStep === 2 && quantity >= 2) return true;
  };

  useEffect(() => {
    if (businessInfoError && businessInfoError?.response?.status === 401) {
      navigate("/auth/login");
    }

    if (businessInfo) {
      const subscription = (businessInfo as any).subscription;

      if (
        subscription?.latest_invoice?.payment_intent?.status === "succeeded"
      ) {
        navigate("/members");
      }

      if (subscription?.status === "incomplete") {
        setQuantity(subscription.quantity);
      }
    }
  }, [businessInfoLoading]);

  if (businessInfoLoading) {
    return (
      <div className="h-full flex items-center justify-center">
        <span className="loading loading-spinner"></span>
      </div>
    );
  }

  return (
    <div className="h-full ">
      <div className="h-[10%]  flex items-center justify-center  bg-gradient-to-r from-base-300 to-base-100 ">
        <AccountSubscriptionSteps
          currentStep={currentStep}
        ></AccountSubscriptionSteps>
      </div>
      <div className="h-[85%] overflow-scroll">
        {currentStep === 0 &&
          (!businessInfoLoading ? (
            <div className="flex items-center justify-center h-full">
              <div className="w-1/2">
                <AccountInfoForm
                  onFinish={async () => {
                    await getBusinessInfo();
                    setCurrentStep(1);
                  }}
                  defaultValues={businessInfo}
                ></AccountInfoForm>
              </div>
            </div>
          ) : (
            <h1>loading</h1>
          ))}
        {currentStep === 1 &&
          (paymentMethod ? (
            <div className="flex items-center justify-center h-full">
              <div className="">
                <CreditCard
                  className="bg-base-200"
                  onEditClick={() => setPaymentMethod(undefined)}
                  card={paymentMethod?.paymentMethod?.card}
                ></CreditCard>
              </div>
            </div>
          ) : (
            <div className="flex items-center justify-center  h-full">
              <div className="w-1/2">
                <ChoosePaymentMethod
                  className="bg-base-200"
                  onComplete={onPaymentMethod}
                ></ChoosePaymentMethod>
              </div>
            </div>
          ))}
        {currentStep === 2 && (
          <div className="flex items-center justify-center h-full">
            <div className="w-[50%] ">
              <ChooseUserQuantity
                quantity={quantity}
                onChange={onQuantityChange}
              ></ChooseUserQuantity>
            </div>
          </div>
        )}
        {currentStep === 3 && (
          <>
            <Checkout
              quantity={quantity}
              card={paymentMethod?.paymentMethod?.card}
            ></Checkout>
            {subscriptionLoading && (
              <div className="flex flex-col items-center justify-center gap-2">
                <div className="text-center text-lg font-bold">
                  Initializing Subscription! Please wait...
                </div>
                <span className="loading loading-spinner"></span>
              </div>
            )}

            {subscriptionStatus === "requires_action" && (
              <div className="pl-10 pr-10">
                <div className="alert alert-warning text-sm ">
                  Your card is secured with 3D Secure. Please await the
                  appearance of the payment confirmation pop-up.{" "}
                  <span className="loading loading-spinner"></span>
                </div>
              </div>
            )}

            {subscriptionStatus === "generic_failure" && (
              <div className="pl-10 pr-10">
                <div className="alert alert-error text-sm ">
                  Payment was unsuccessful. Please try again with an alternative
                  card.
                </div>
              </div>
            )}

            {subscriptionStatus === "succeeded" && (
              <div className="pl-10 pr-10">
                <div className="alert alert-success text-sm ">
                  <CountedRedirect
                    text={"Payment processed succsefully."}
                    seconds={3}
                    path={"/members"}
                  ></CountedRedirect>
                </div>
              </div>
            )}

            {subscriptionError && (
              <div className="pl-10 pr-10">
                <div className="alert alert-error text-sm mt-6 text-center">
                  {subscriptionError?.response?.data?.message}
                </div>
              </div>
            )}

            {paymentIntentClientSecret && (
              <StripePaymentConfirmation
                secret={paymentIntentClientSecret}
                onComplete={on3DSecurePaymentConfirmation}
              ></StripePaymentConfirmation>
            )}
          </>
        )}
      </div>

      <div className="h-[5%] flex items-center bg-gradient-to-r from-base-300 to-base-100  ">
        {currentStep > 0 && (
          <button
            onClick={() => {
              let nextStep = currentStep - 1;
              setCurrentStep(nextStep);
              localStorage.setItem("currentStep", nextStep.toString());
            }}
            disabled={subscriptionLoading}
            className="btn btn-sm btn-ghost"
          >
            <ArrowLeftIcon className="h-4"></ArrowLeftIcon>Previous Step
          </button>
        )}
        {currentStep === 3 ? (
          <button
            disabled={subscriptionLoading}
            onClick={onSubscribe}
            className="btn btn-primary btn-sm ml-auto "
          >
            Subscribe
          </button>
        ) : (
          <button
            onClick={() => {
              let nextStep = currentStep + 1;
              setCurrentStep(nextStep);
              localStorage.setItem("currentStep", nextStep.toString());
            }}
            disabled={!canMoveToNextStep() || subscriptionLoading}
            className="btn btn-primary btn-sm ml-auto btn-ghost text-primary"
          >
            Next Step<ArrowRightIcon className="h-4"></ArrowRightIcon>
          </button>
        )}
      </div>
    </div>
  );
}

function UserQuantityCounter(props: { onChange: any; quantity: number }) {
  return (
    <div className="flex items-center justify-between gap-2 bg-base-300 p-5 rounded-xl">
      <div className="w-full  flex items-center justify-center">
        <button
          disabled={props.quantity <= 2}
          onClick={() => props.onChange(props.quantity - 1)}
          className="btn btn-sm btn-circle btn-primary"
        >
          <MinusIcon className="h-4"></MinusIcon>
        </button>
      </div>
      <input
        min={2}
        className="font-bold text-4xl h-full rounded  w-full text-center input"
        // defaultValue={props.quantity}

        value={props.quantity}
        onChange={(e) => {
          const value = parseInt(e?.target?.value);

          props.onChange(value ? value : 0);
        }}
      ></input>
      <div className="w-full flex items-center justify-center">
        <button
          onClick={() => props.onChange(props.quantity + 1)}
          className="btn btn-sm btn-circle btn-primary"
        >
          <PlusIcon className="h-4"></PlusIcon>
        </button>
      </div>
    </div>
  );
}

function ChooseUserQuantity(props: {
  quantity: number;
  onChange: any;
  isUpdate?: boolean;
  onUpdate?: any;
}) {
  return (
    <div className="card bg-base-200">
      <div className="card-body">
        <div className="flex flex-col gap-4">
          <div className="text-lg font-bold">Pricing</div>
          <div className="flex flex-col gap-4 items-center justify-center">
            <div className="text-xl font-bold">Number of users</div>
            <div className="w-full">
              <UserQuantityCounter
                quantity={props.quantity}
                onChange={props.onChange}
              ></UserQuantityCounter>
            </div>
          </div>
          <div className="">
            <div className="flex flex-col gap-1">
              <div className=" text-md opacity-75 ">$50 per user</div>
              <div className=" text-md opacity-75">
                {props.quantity} users * $50/month
              </div>
              <div className="divider m-0"></div>
              <div className="flex">
                <div className=" text-xl text-primary font-bold">
                  ${props.quantity * 50}/month
                </div>
                {props?.isUpdate && (
                  <button
                    onClick={props?.onUpdate}
                    className="btn btn-primary btn-sm ml-auto animate-pulse "
                  >
                    Update Subscription
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ChoosePaymentMethod(props: { onComplete: any; className?: string }) {
  return (
    <div className={`card ${props.className}`}>
      <div className={`card-body p-0`}>
        <PaymentMethod onComplete={props.onComplete}></PaymentMethod>
      </div>
    </div>
  );
}

function CreditCard(props: {
  card: any;
  onEditClick?: any;
  className?: string;
}) {
  return (
    <div className={`card ${props?.className}`}>
      <div className={`card-body`}>
        <div className="flex gap-10 ">
          <img className="h-12" src={`/cards/${props?.card?.brand}.svg`}></img>
          <div className="flex flex-col font-bold justify-center">
            <div className="text-center">
              **** **** **** {props?.card?.last4}
            </div>
            <div className="text-sm opacity-75">
              Expire: {props?.card?.exp_month}/{props?.card?.exp_year}
            </div>
          </div>
          {props.onEditClick && (
            <div className="flex items-center ml-auto">
              <button
                onClick={props?.onEditClick}
                className="btn btn-sm ml-auto text-primary"
              >
                <PencilIcon className="h-4" />
                Edit
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function Checkout(props: { quantity?: number; card?: any }) {
  return (
    <div className="card ">
      <div className="card-body ">
        <div className="font-bold text-xl">Card</div>
        <div>
          <CreditCard card={props.card}></CreditCard>
          <div className="text-sm opacity-75">
            This is your default payment method for monthly charges. You can
            later update it in your Account Settings.
          </div>
        </div>
        <div className="divider"></div>
        <div className="font-bold text-xl">Details</div>
        <div className="font-bold text-center">
          {props?.quantity} active users * $50/month ={" "}
          <span className="text-primary">
            ${(props?.quantity as number) * 50}/month
          </span>
        </div>
        {/* <div className="text-sm opacity-75">
          Every time you add a new user apart from the ones you choose now,
          you'll be charged automatically and an invoice will be made.
        </div> */}
      </div>
    </div>
  );
}

const steps = [
  "Business Information",
  "Set Payment Method",
  "Choose Quantity",
  "Subscribe",
];

export function AccountSubscriptionSteps(props: { currentStep: number }) {
  return (
    <ul className="steps steps-horizontal w-full">
      {steps.map((step, index) => (
        <li
          className={`step text-sm ${
            props.currentStep >= index && "step-primary"
          }`}
        >
          {step}
        </li>
      ))}
    </ul>
  );
}
