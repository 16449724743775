import { PencilIcon } from "@heroicons/react/24/outline";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";

const elementStyles = {
  base: {
    color: "white",
    fontWeight: 500,
    fontFamily: "Inter, Open Sans, Segoe UI, sans-serif",
    fontSize: "18px",
    fontSmoothing: "antialiased",
    "::placeholder": {
      color: "rgba(207,215,223,0.19)",
    },
  },
  invalid: {
    color: "#E25950",
  },
};

const elementClasses = {
  focus: "focus",
  empty: "empty",
  invalid: "invalid",
};

const clientSecret =
  "pk_test_51JlqwbGEHS8rZHSiSM12M0Rvqaa1MSRTwJCLQkgbZDrZS6djgHBlVxSL0xbtjHX2RolRTGtG04ZNf3eux4FQYPGu00uqU12ByA";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(clientSecret);

export function PaymentMethod(props: { hidden?: boolean, onComplete: any, setUpdate?: any }) {
  return (
    <Elements stripe={stripePromise}>
      <Form hidden={props.hidden} onComplete={props.onComplete} setUpdate={props.setUpdate}></Form>
    </Elements>
  );
}

export function StripePaymentConfirmation(props: {secret: string, onComplete: any}) {
  return (
    <Elements stripe={stripePromise}>
      <Stripe3DSecureHandler secret={props.secret} onComplete={props.onComplete}></Stripe3DSecureHandler>
    </Elements>
  )
}

function Stripe3DSecureHandler(props: {secret: string, onComplete: any}) {

  const stripe = useStripe();

  useEffect(() => {  
    stripe?.confirmCardPayment(props.secret).then(response => {
      props.onComplete(response);
    })
  }, [stripe])

  return (
    <></>
  )
}



function Form(props: { hidden?: boolean, onComplete: any, setUpdate: any }) {
  const stripe = useStripe();

  const [cardInstance, setCardInstace] = useState({} as any);
  const [number, setNumber] = useState(false);
  const [expiry, setExpiry] = useState(false);
  const [cvc, setCVC] = useState(false);
  const [loading, setLoading] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState(undefined as any);

  const numberCompleteHandler = (elementInstance: any) => {
    setNumber(true);
    setCardInstace(elementInstance);
  };

  const submitHandler = async () => {
    setLoading(true);
    const paymentMethod = await stripe?.createPaymentMethod({
      type: "card",
      card: cardInstance,
    });
    setPaymentMethod(paymentMethod);
    setLoading(false);
    props.onComplete(paymentMethod);
  };

  return (
    <div hidden={props.hidden}>
      <div className="relative">
        <div className="p-4">
          <StripeElement
            onError={() => setNumber(false)}
            onComplete={numberCompleteHandler}
            top="Number"
            type="cardNumber"
          ></StripeElement>
          <div className="flex gap-4">
            <StripeElement
              onError={() => setExpiry(false)}
              onComplete={() => setExpiry(true)}
              top="Expiry"
              type="cardExpiry"
            ></StripeElement>
            <StripeElement
              onError={() => setCVC(false)}
              onComplete={() => setCVC(true)}
              top="CVC"
              type="cardCvc"
            ></StripeElement>
          </div>
          <button
            onClick={submitHandler}
            disabled={!number || !expiry || !cvc || paymentMethod}
            className={`btn btn-primary w-full mt-4`}
          >
            {loading? (
                <span className="loading loading-spinner "></span>
            ): ''}
           
            Submit
          </button>
          {props.setUpdate ? (<button
            onClick={() => props.setUpdate(false)}
            className={`btn btn-secondary w-full mt-4`}
          >
            Cancle
          </button>) : (null)}
          
        </div>
        {/* {paymentMethod ? (
          <div className="absolute rounded-xl top-0 bg-black bg-opacity-40 h-full w-full flex items-center justify-center">
            <button onClick={() => setPaymentMethod(undefined)} className="btn btn-circle btn-primary">
              <PencilIcon className="h-4"></PencilIcon>
            </button>
          </div>
        ) : (
          ""
        )} */}
      </div>
    </div>
  );
}

function StripeElement(props: {
  top: string;
  type: string;
  onComplete: any;
  onError: any;
}) {
  const elements = useElements();
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    const element = elements?.create(props.type as any, {
      style: elementStyles,
      classes: elementClasses,
    });
    element?.mount(`#${props.type}`);

    element?.on("change", (event: any) => {
      if (event.complete) {
        props.onComplete(element);
        setErrors([]);
        return;
      }

      if (event.error) {
        setErrors([event.error.message] as any);
        props.onError();
      }

      if (event.empty) {
        setErrors([] as any);
      }
    });
  }, [elements]);

  return (
    <div className="form-control w-full ">
      <label className="label">
        <span className="label-text text-opacity-50">{props.top}</span>
      </label>
      <div
        id={props.type}
        className="p-4 cursor-text bg-base-100 rounded-xl"
      ></div>
      {errors ? (
        <div className="">
          {errors?.map((msg: any, index: number) => {
            return (
              <label key={index} className="label">
                <span className="label-text-alt text-red-600">{msg}</span>
              </label>
            );
          })}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
