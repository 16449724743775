import { Outlet, useNavigate } from "react-router-dom";
import { createContext, useEffect, useState } from "react";
import NavbarLeft from "./components/navigation/NavbarLeft";
import NavbarTop from "./components/navigation/NavbarTop";
import useAxios from "axios-hooks";
import { log } from "console";

export const UserContext = createContext({} as any);

function App() {
  const navigate = useNavigate();

  const [titleComponent, setTitleComponent] = useState(undefined as any);

  const [isCollapsed, setIsCollapsed] = useState(() => {
    let collapsed = JSON.parse(localStorage.getItem("isCollapsed") as string)
      ? true
      : false;
    return collapsed;
  });

  const [
    { data: user, loading: userLoading, error: userError },
    getAuthenticatedUser,
  ] = useAxios(
    {
      url: "/auth/user",
    },
    { manual: true }
  );

  const [
    {
      data: subscription,
      loading: subscriptionLoading,
      error: subscriptionError,
    },
    getSubscription,
  ] = useAxios(
    {
      url: "/payments/subscription",
    },
    { manual: true }
  );

  useEffect(() => {
    getAuthenticatedUser()
      .then((user) => {
        getSubscription()
          .then((subscription) => {
            //businessInfo?.data?.subscription.latest_invoice.payment_intent.status
            const latestInvoice = subscription?.data?.latest_invoice
            const paymentIntent = latestInvoice.payment_intent
            
            if(!subscription?.data) {
              navigate("/subscription/init");
            }

            if(!latestInvoice.paid && latestInvoice.billing_reason === "subscription_create") {
              return navigate("/subscription/init");
            }

            if(!latestInvoice.paid && latestInvoice.billing_reason === "subscription_update") {
              return navigate("/account/subscription");
            }

            if(!latestInvoice.paid && latestInvoice.billing_reason === "subscription_cycle") {
              return navigate("/account/subscription");
            }

          })
          .catch((error) => {
            navigate("/subscription/init");
          });
      })
      .catch((error) => {
        navigate("/auth/login");
      });
  }, []);

  if (!user || !subscription) {
    return (
      <div className="flex items-center justify-center h-screen w-screen">
        <div className="loading loading-spinner"></div>
      </div>
    );
  }

  return (
    <div className="flex h-screen">
      <div
        className={`${
          !isCollapsed ? "w-[15%]" : "w-[4.2%]"
        }  bg-base-200 transition-[width]`}
      >
        <NavbarLeft
          isCollapsed={isCollapsed}
          onCollapseChange={(collapsed: boolean) => {
            setIsCollapsed(collapsed);
            localStorage.setItem("isCollapsed", JSON.stringify(collapsed));
          }}
        ></NavbarLeft>
      </div>
      <div
        className={` ${
          !isCollapsed ? "w-[85%]" : "w-full"
        }  overflow-scroll transition-[width]`}
      >
        <NavbarTop titleComponent={titleComponent}></NavbarTop>

        <UserContext.Provider value={user}>
          <div>
            <Outlet context={{ setTitleComponent }}></Outlet>
          </div>
        </UserContext.Provider>
      </div>
    </div>
  );
}

export default App;
