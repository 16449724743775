import ChangePassword from "./ChangePassword";
import MFA from "./MFA";

export default function AccountSettings() {
  return (
    <div className="flex flex-col gap-2">
    <div className="text-lg font-bold">Password</div>
    <ChangePassword></ChangePassword>
    <div className="text-lg font-bold">Multi-Factor Authentication</div>
    <MFA></MFA>
  </div>
  );
}