import { useForm, SubmitHandler, Controller } from "react-hook-form";
import useAxios from "axios-hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { BusinessResDto, CreateBusinessDto } from "../../api/Api";
import Select from "react-select";
import CountrySelect, {
  CitySelect,
} from "../../components/input/CountrySelect";

export default function AccountInfo() {

  const [{loading, data, error}] = useAxios<BusinessResDto>(`/business`)

  if(loading) {
    return <h1>Loading</h1>
  }
  

  return (
    <div className="card bg-base-200 w-1/2">
      <div className="card-body ">
        <AccountInfoForm defaultValues={data}></AccountInfoForm>
      </div>
    </div>
  );
}

export function AccountInfoForm(props: {
  defaultValues?: BusinessResDto;
  onFinish?: any;
}) {
  const { state } = useLocation();

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<CreateBusinessDto>();

  const country = watch("country");

  const [{ loading, error }, createInfo] = useAxios<
    BusinessResDto,
    CreateBusinessDto
  >(
    {
      url: "/business",
    },
    {
      manual: true,
    }
  );

  const onSubmit: SubmitHandler<CreateBusinessDto> = async (data) => {
    try {
      await createInfo({
        method: props.defaultValues ? "PATCH" : "POST",
        data: {
          ...data,
          postalCode: parseInt(data.postalCode.toString()),
        },
      });
      props.onFinish();
    } catch (error) {}
  };

  return (
    <div className="">
      <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
        <label className="form-control w-full">
          <div className="label">
            <span className="label-text">Business Name</span>
          </div>
          <input
            defaultValue={props.defaultValues?.businessName}
            disabled={loading}
            {...register("businessName", {
              required: true,
            })}
            type="text"
            className="input input-bordered input-sm w-full"
          />
          <div className="label">
            {errors?.businessName?.type === "required" && (
              <span className="label-text-alt text-error">
                This field is required
              </span>
            )}
          </div>
        </label>

        <label className="form-control w-full">
          <div className="label">
            <span className="label-text">Country</span>
          </div>
          <input
            defaultValue={props.defaultValues?.country}
            disabled={loading}
            {...register("country", {
              required: true,
            })}
            type="text"
            className="input input-bordered input-sm w-full"
          />
          <div className="label">
            {errors?.country?.type === "required" && (
              <span className="label-text-alt text-error">
                This field is required
              </span>
            )}
          </div>
        </label>

        <label className="form-control w-full">
          <div className="label">
            <span className="label-text">City</span>
          </div>
          <input
            disabled={loading}
            defaultValue={props.defaultValues?.city}
            {...register("city", {
              required: true,
            })}
            type="text"
            className="input input-bordered input-sm w-full"
          />
          <div className="label">
            {errors?.city?.type === "required" && (
              <span className="label-text-alt text-error">
                This field is required
              </span>
            )}
          </div>
        </label>

        <label className="form-control w-full">
          <div className="label">
            <span className="label-text">Address</span>
          </div>
          <input
            defaultValue={props.defaultValues?.address}
            disabled={loading}
            {...register("address", {
              required: true,
            })}
            type="text"
            className="input input-bordered input-sm w-full"
          />
          <div className="label">
            {errors?.address?.type === "required" && (
              <span className="label-text-alt text-error">
                This field is required
              </span>
            )}
          </div>
        </label>

        <label className="form-control w-full">
          <div className="label">
            <span className="label-text">Postal Code</span>
          </div>
          <input
            defaultValue={props.defaultValues?.postalCode}
            disabled={loading}
            {...register("postalCode", {
              required: true,
            })}
            type="text"
            className="input input-bordered input-sm w-full"
          />
          <div className="label">
            {errors?.postalCode?.type === "required" && (
              <span className="label-text-alt text-error">
                This field is required
              </span>
            )}
          </div>
        </label>

        <label className="form-control w-full">
          <div className="label">
            <span className="label-text">Phone Number</span>
          </div>
          <input
            defaultValue={props.defaultValues?.phoneNumber}
            disabled={loading}
            {...register("phoneNumber", {
              required: true,
            })}
            type="text"
            className="input input-bordered input-sm w-full"
          />
          <div className="label">
            {errors?.phoneNumber?.type === "required" && (
              <span className="label-text-alt text-error">
                This field is required
              </span>
            )}
          </div>
        </label>

        {/* <label className="form-control">
          <div className="label">
            <span className="label-text">Country</span>
          </div>
          <Controller
            name="country"
            control={control}
            render={({ field: { onChange } }) => (
              <CountrySelect
                onSelect={(country: string) => onChange(country)}
              ></CountrySelect>
            )}
            rules={{ required: true }}
          />
          <div className="label">
            {errors?.country?.type === "required" && (
              <span className="label-text-alt text-error">
                This field is required
              </span>
            )}
          </div>
        </label>

        {country && (
          <label className="form-control">
            <div className="label">
              <span className="label-text">City</span>
            </div>
            <Controller
              name="city"
              control={control}
              render={({ field: { onChange } }) => (
                <CitySelect
                  country={country}
                  onSelect={(city: string) => onChange(city)}
                />
              )}
              rules={{ required: true }}
            />
            <div className="label">
              {errors?.city?.type === "required" && (
                <span className="label-text-alt text-error">
                  This field is required
                </span>
              )}
            </div>
          </label>
        )} */}

        <div className="pt-2 w-full">
          <button
            className="btn btn-sm w-full btn-primary"
            disabled={loading}
            type="submit"
          >
            Submit
          </button>
        </div>
        {error && (
          <div className="alert alert-error text-sm mt-6 text-center">
            {error?.response?.data?.message}
            {error?.response?.data?.__type === "UserNotConfirmedException" && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                }}
                className="btn btn-xs ml-auto"
              >
                Resend code
              </button>
            )}
          </div>
        )}
        {loading && (
          <div className="flex items-center justify-center mt-6">
            <div className="loading loading-spinner "></div>
          </div>
        )}
        {state?.confirmed && (
          <div className="alert alert-success text-sm mt-6 text-center">
            {state?.message}
          </div>
        )}
      </form>

      {/* <div className="flex flex-col gap-2 items-center">
        <AuthLink
          label="Sign Up"
          message="You dont have an account?"
          path="/auth/signup"
        ></AuthLink>
        <AuthLink
          label="Forgot Password"
          message="You forgot your password?"
          path="/auth/forgot-password"
        ></AuthLink>
      </div> */}
    </div>
  );
}
