import { ClockIcon, TagIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import {
  calculateProgress,
  secondsToHoursAndMinutes,
} from "../../util/helpers";

export default function ContentTable(props: {
  data: any;
  contentType: string;
}) {
  const navigate = useNavigate();

  return (
    <table className="table table-fixed w-full">
      <tbody>
        {props.data?.map((content: any) => {
          const contentData = content?.content[0];
          return (
            <tr className="hover:bg-base-300 cursor-pointer" key={content?._id}>
              <td className="w-[5rem]">
                <div className="avatar">
                  <div className="rounded-full">
                    <img
                      className=""
                      src={`https://static.socora.com/img/${props.contentType}/small/${contentData?._id}.jpg`}
                    ></img>
                  </div>
                </div>
              </td>
              <td className="pl-0">
                <div className="flex flex-col gap-2">
                  <div className="sm:text-sm text-xs font-bold h-full">
                    {contentData?.name}
                  </div>
                  <div className="flex gap-4 items-center">
                    <div>
                      <div className="badge gap-2 border-0 p-0 bg-transparent text-xs">
                        <ClockIcon className="h-3"></ClockIcon>
                        {secondsToHoursAndMinutes(
                          contentData?.info?.duration as number
                        )}
                      </div>
                    </div>
                    {contentData?.tags?.slice(0, 2).map((tag: any) => (
                      <div
                        key={tag?._id}
                        className="badge sm:flex hidden gap-2 border-0 p-0 bg-transparent text-xs"
                      >
                        <TagIcon className="h-3"></TagIcon>
                        {tag?.value}
                      </div>
                    ))}
                  </div>

                  {!content?.finished ? (
                    <progress
                      max="100"
                      className="h-[0.2rem] w-full progress progress-primary bg-white bg-opacity-10 "
                      value={calculateProgress(
                        content?.finishedUnits as number,
                        content?.totalUnits as number
                      )}
                    ></progress>
                  ) : (
                    ""
                  )}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
