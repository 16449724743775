import useAxios from "axios-hooks";
import { useNavigate, useParams } from "react-router-dom";
import { UserResDto, UsersResDto } from "../../api/Api";
import MemberForm from "./form/MemberForm";
import {
  CheckIcon,
  KeyIcon,
  TrashIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import ConfirmationButton from "../../components/button/ConfirmationButton";

export default function MemberSettings(props: {}) {
  const params = useParams();
  const navigate = useNavigate();

  const [{ data, loading, error }, getMember] = useAxios<UserResDto>(
    `/users/${params.memberId}`
  );

  const [
    { data: deleteData, loading: deleteLoading, error: deleteError },
    deleteMember,
  ] = useAxios<UserResDto>(
    {
      url: `/users/${params.memberId}`,
      method: "DELETE",
    },
    { manual: true }
  );

  const [
    {
      data: changeUserStatusData,
      loading: changeUserStatusLoading,
      error: changeUserStatusError,
    },
    changeUserStatus,
  ] = useAxios<UsersResDto>(
    {
      url: `/users/${params.memberId}/status`,
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const handleDelete = async () => {
    try {
      await deleteMember();
      navigate("/members");
    } catch (error) {}
  };

  const handleChangeStatus = async (status: string) => {
    try {
      const res = await changeUserStatus({
        data: {
          status: status,
        },
      });
      getMember();
    } catch (error) {}
  };

  if (loading) return <h1>Loading...</h1>;
  if (changeUserStatusLoading) return <h1>Changing user status...</h1>;
  if (deleteLoading) return <h1>Deleting Member...</h1>;

  return (
    <div className="flex flex-col gap-4">
      <div className="card bg-base-200 w-1/2">
        <div className="card-body">
          <MemberForm defaultValues={data} onSuccess={() => {}}></MemberForm>
          <div className="divider"></div>

          {!data?.enabled && (
            <>
              <div className="italic text-sm text-warning">
                This member is disabled and cannot be used to access Socora App.
                You can enable it by clicking `Enable Access` button below.
              </div>
              <div className="divider"></div>
            </>
          )}

          <div className="flex flex-col gap-4">
            <div className="join">
              <div className="  w-full ">
                <button className="btn btn-ghost btn-xs w-full join-item">
                  <KeyIcon className="h-4"></KeyIcon>CHANGE PASSWORD
                </button>
              </div>
              <div className="  w-full">
                {data?.enabled && (
                  <ConfirmationButton
                    id={"change_memeber_status_modal_1"}
                    buttonText={"DISABLE ACCESS"}
                    body={`Are you sure you want to disable access for member '${data?.username}' ?`}
                    title={"Disable Access"}
                    onAccept={() => handleChangeStatus("DISABLE")}
                    onReject={() => {}}
                    className={"btn btn-ghost btn-xs w-full join-item"}
                    icon={<XCircleIcon className="h-4"></XCircleIcon>}
                  ></ConfirmationButton>
                )}

                {!data?.enabled && (
                  <ConfirmationButton
                    id={"change_memeber_status_modal_2"}
                    buttonText={"ENABLE ACCESS"}
                    body={`Are you sure you want to enable access for member '${data?.username}' ?`}
                    title={"Enable Access"}
                    onAccept={() => handleChangeStatus("ENABLE")}
                    onReject={() => {}}
                    className={"btn btn-ghost btn-xs w-full join-item"}
                    icon={<CheckIcon className="h-4"></CheckIcon>}
                  ></ConfirmationButton>
                )}
              </div>
              <div className="  w-full">
                <ConfirmationButton
                  id={"delete_member_modal"}
                  buttonText={"DELETE"}
                  body={`Are you sure you want to delete member '${data?.username}' ?`}
                  title={"Delete Member"}
                  onAccept={handleDelete}
                  onReject={() => {}}
                  className={"btn btn-ghost btn-xs w-full join-item text-error"}
                  icon={<TrashIcon className="h-4"></TrashIcon>}
                ></ConfirmationButton>
              </div>
            </div>
          </div>
          {changeUserStatusError && (
            <>
              <div className="alert alert-error text-sm mt-4">
                {changeUserStatusError.response?.data?.message}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
