import {
  Chart as ChartJS,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  PointElement,
  LineElement,
  Filler,
  ScriptableContext,
  LogarithmicScale,
  TimeSeriesScale,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-moment";

import moment from "moment";
import DatePicker from "react-datepicker";
import { useEffect, useState } from "react";
import { enumerateDaysBetweenDates, prepareChartData, secondsToHoursAndMinutes } from "../../util/helpers";
import useAxios from "axios-hooks";
import { useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

ChartJS.register(
  TimeScale,
  LogarithmicScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeSeriesScale,
  Filler
);

// function parsePropsData(data: any, label:string, colorRGB:string) {
//   return {
//     data: data,
//     label: label,
//     tension: 0.2,
//     borderColor: `rgba(${colorRGB})`,
//     backgroundColor: (context: ScriptableContext<"line">) => {
//       const ctx = context.chart.ctx;
//       const gradient = ctx.createLinearGradient(0, 0, 0, 200);
//       gradient.addColorStop(0, `rgba(${colorRGB}, 2)`);
//       gradient.addColorStop(1, `rgba(${colorRGB}, 0.1)`);
//       return gradient;
//     }
//   }
// }

const colorRGB = "245, 31, 111";

function getGroupingUnit(days: number) {
  if (days > 60 && days < 120) {
    return {
      unit: "day",
      round: "day",
    };
  }

  if (days > 120 && days < 720) {
    return {
      unit: "month",
      round: "month",
    };
  }

  if (days > 720) {
    return {
      unit: "year",
      round: "year",
    };
  }

  return {
    unit: "day",
    round: "day",
  };
}

export default function ProfileAreaChart() {
  const params = useParams();

  const [startDate, setStartDate] = useState(
    moment().subtract(15, "days").valueOf()
  );
  const [endDate, setEndDate] = useState(moment().valueOf());

  const daysBetween = enumerateDaysBetweenDates(startDate, endDate);

  const [{ data, loading }] = useAxios({
    url: `users/${params.memberId}/watchTime`,
    params: {
      from: startDate,
      to: endDate,
    },
  });

  return (
    <div className="w-full">
      <div className="flex xl:flex-row flex-col">
        <div className="stat-value text-sm pb-2">
          Watch Time
        </div>
        <div className="flex sm:flex-row flex-col xl:ml-auto xl:gap-0 gap-2 xl:items-center items-start xl:justify-center">
          <div className="flex sm:flex-row flex-col sm:items-center zm:justify-center">
            <div className="text-sm ">From</div>
            <div className="sm:pl-2 sm:pt-0 pt-2">
              <DatePicker
                className="bg-base-300"
                selected={startDate as any}
                customInput={
                  <input className="input input-sm "></input>
                }
                onChange={(date) =>
                  setStartDate(moment(date).startOf("day").valueOf() as any)
                }
              />
            </div>
          </div>

          <div className="flex sm:flex-row flex-col sm:items-center sm:justify-center">
            <div className="text-sm xl:pl-2 p-0">
              To
            </div>
            <div className="sm:pl-2 sm:pt-0 pt-2">
              <DatePicker
                className="bg-base-300"
                selected={endDate as any}
                customInput={
                  <input className="input input-sm "></input>
                }
                onChange={(date) =>
                  setEndDate(moment(date).endOf("day").valueOf() as any as any)
                }
              />
            </div>
          </div>
        </div>
      </div>

      {!loading ? (
        <div className=" h-[27rem] w-full flex items-center justify-center">
          <Line
            options={{
              events: [
                "mousemove",
                "mouseout",
                "click",
                "touchstart",
                "touchmove",
              ],
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  callbacks: {
                    label: (context) => {
                      return secondsToHoursAndMinutes(context.parsed.y);
                    },
                  },
                },
              },
              scales: {
                x: {
                  type: "time",
                  time: {
                    unit: "day",
                    round: "day",
                  },
                  grid: {
                    color: "rgba(0, 0, 0, 0.1)",
                  },
                },
                y: {
                  min: 0,
                  ticks: {
                    stepSize: 1,
                    callback: (data) => {
                      return secondsToHoursAndMinutes(data as number);
                    },
                  },
                  beginAtZero: false,
                  afterDataLimits: (axis) => {
                    axis.max += axis.max === 1 ? 1 : Math.floor(axis.max / 2);
                  },
                  grid: {
                    color: "rgba(0, 0, 0, 0.1)",
                  },
                },
              },
            }}
            data={{
              datasets: [
                {
                  data: prepareChartData(data, daysBetween),
                  label: "Watch Time",
                  tension: 0.2,
                  borderColor: `rgba(${colorRGB})`,
                  fill: true,
                  backgroundColor: (context: any) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 0, 0, 200);
                    gradient.addColorStop(0.5, `rgba(${colorRGB}, 0.5)`);
                    gradient.addColorStop(1, `rgba(${colorRGB}, 0.05)`);

                    return gradient;
                  },
                },
              ],
            }}
          />
        </div>
      ) : (
        <div className="flex h-[27rem] items-center justify-center">
          <span className="loading loading-spinner"></span>
        </div>
      )}
    </div>
  );
}
