import { SubmitHandler, useForm, useFormContext } from "react-hook-form";
import useAxios from "axios-hooks";
import { AuthLink } from "./components/AuthLink";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { useContext, useEffect, useState } from "react";
import * as QRCode from "qrcode";
import { UserContext } from "../../App";

type Inputs = {
  recoveryCode: string;
  username: string;
  password: string;
};

export default function RecoverMFA() {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<Inputs>();
  

  const [{ data, loading, error }, recoverMFA] = useAxios(
    {
      url: "/auth/mfa/recover",
      method: "POST",
    },
    {
      manual: true,
    }
  );
 
  const [qrURL, setQrURL] = useState("");

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      let response = await recoverMFA({
        data: data,
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (!loading && !error && data) {
      QRCode.toDataURL(
        `otpauth://totp/Socora(${getValues()?.username})?secret=${data?.SecretCode}`,
        (err, url) => {
          setQrURL(url);
        }
      );
    }
  }, [loading]);

  if (data) {
    return (
      <div className="flex items-center justify-center flex-col h-full gap-4">
        <div className="alert text-sm w-1/2">
          <ExclamationCircleIcon className="h-5"></ExclamationCircleIcon>
          {data?.message}. Please scan the following QR Code with your preferred
          Authenticator app and proceed to login.
        </div>
        <div>
          <div className="bg-base-200 p-4 flex items-center justify-center rounded-xl">
            <img alt="" src={qrURL} className="rounded-xl w-[150px]"></img>
          </div>
          
        </div>
        <div className="items-center justify-center flex">
            <AuthLink
              label="Back To Login"
              message=""
              path="/auth/login"
            ></AuthLink>
          </div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center flex-col h-full gap-4">
      <div className="alert text-sm w-1/2">
        <ExclamationCircleIcon className="h-5"></ExclamationCircleIcon>
        Please provide a valid recovery key you received when you set up MFA
        along with your username and password.
      </div>
      <form className="flex flex-col w-1/2" onSubmit={handleSubmit(onSubmit)}>
        <label className="form-control w-full">
          <div className="label">
            <span className="label-text">Username</span>
          </div>
          <input
            disabled={loading}
            {...register("username", {
              required: true,
            })}
            type="text"
            className="input input-bordered input-sm w-full"
          />
          <div className="label">
            {errors?.username?.type === "required" && (
              <span className="label-text-alt text-error">
                This field is required
              </span>
            )}
          </div>
          <div className="label">
            <span className="label-text">Password</span>
          </div>
          <input
            disabled={loading}
            {...register("password", { required: true })}
            type="text"
            className="input input-bordered input-sm w-full"
          />
          <div className="label">
            {errors?.password?.type === "required" && (
              <span className="label-text-alt text-error">
                This field is required
              </span>
            )}
          </div>
          <div className="label">
            <span className="label-text">Recovery Key</span>
          </div>
          <input
            disabled={loading}
            {...register("recoveryCode", {
              required: true,
            })}
            className="input input-bordered input-sm w-full text-center font-bold"
          />
          <div className="label">
            {errors?.recoveryCode?.type === "required" && (
              <span className="label-text-alt text-error">
                This field is required
              </span>
            )}
          </div>
        </label>
        <div className="pt-2 w-full">
          <button
            className="btn btn-sm w-full btn-primary"
            disabled={loading}
            type="submit"
          >
            Recover MFA
          </button>
        </div>

        {error && (
          <div className="alert alert-error text-sm mt-6 text-center">
            {error?.response?.data?.message}
          </div>
        )}

        {loading && (
          <div className="flex items-center justify-center mt-6">
            <div className="loading loading-spinner "></div>
          </div>
        )}
      </form>

      <div className="flex flex-col gap-2 items-center">
        <AuthLink
          label="Back To Login"
          message=""
          path="/auth/login"
        ></AuthLink>
      </div>
    </div>
  );
}
