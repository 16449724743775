import { useEffect, useRef, useState } from "react";
import useOnScreen from "../../components/navigation/useOnScreen";
import {
  AcademicCapIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  BeakerIcon,
  ClockIcon,
} from "@heroicons/react/24/outline";
import useAxios from "axios-hooks";
import { useParams } from "react-router-dom";
import { secondsToHoursAndMinutes } from "../../util/helpers";
import ProfileAreaChart from "../../components/stats/ProfileAreaChart";
import TopicActivityChart from "../../components/stats/TopicActivityChart";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { CurrenltyWatchingTabs } from "../../components/stats/CurrentlyWatchingDropdown";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 7,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1350 },
    items: 4,
  },
  smallDesktop: {
    breakpoint: { max: 1350, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
  },
};

export default function MemberStats(props: {}) {
  const elementRef = useRef<HTMLDivElement>(null);
  const isOnScreen = useOnScreen(elementRef);
  const [dataFetched, setDataFetched] = useState(false);

  const [clickedBadge, setClickedBadge] = useState(undefined as any);

  useEffect(() => {
    if(isOnScreen) {
      setDataFetched(true);
    }
  }, [isOnScreen]);

  return (
    <div>
      <BadgeDialog
        onClose={() => setClickedBadge(undefined)}
        open={clickedBadge}
        badge={clickedBadge}
        isFinish={false}
      ></BadgeDialog>
      <div className="flex 2xl:flex-col md:flex-row flex-col 2xl:gap-0 gap-5">
        <div className="stats shadow 2xl:stats-horizontal stats-vertical 2xl:w-full md:w-[30%] w-full">
          <TotalWatchTime></TotalWatchTime>
          <CountStat
            content="course"
            description="total finished courses"
            icon={<AcademicCapIcon className="h-7"></AcademicCapIcon>}
          ></CountStat>
          <CountStat
            content="lab"
            description="total finished labs"
            icon={<BeakerIcon className="h-7"></BeakerIcon>}
          ></CountStat>
          <CountStat
            content="path"
            description="total finished paths"
            icon={<AcademicCapIcon className="h-7"></AcademicCapIcon>}
          ></CountStat>
        </div>

        <div className="flex 2xl:flex-row flex-col gap-5 2xl:pt-5 pt-0 2xl:w-full xl:w-[80%] lg:w-[75%] md:w-[70%] w-full">
          <div className="bg-base-200 p-3 rounded-xl flex flex-col">
            <div className="stat-value text-sm  flex item-start">
              Topic Activity
            </div>

            <div className="flex items-center justify-center">
              <div className=" sm:h-[27rem] sm:w-[27rem] h-[20rem] w-[20rem] flex items-center justify-center">
                <TopicActivityChart></TopicActivityChart>
              </div>
            </div>
          </div>
          <div className="w-full bg-base-200 rounded-xl p-3 ">
            <ProfileAreaChart></ProfileAreaChart>
          </div>
        </div>
      </div>
      <div className="pt-5 ">
        <div className="w-full bg-base-200 rounded-xl p-3 ">
          <div className="stat-value text-sm  pb-2">
            Badges
          </div>
          <BadgesCarousel
            openBadgeHandler={(badge: any) => setClickedBadge(badge)}
          ></BadgesCarousel>
        </div>
      </div>

      <div ref={elementRef} className="pt-5 flex xl:flex-row flex-col gap-5 ">
        <div className="w-full bg-base-200 rounded-xl p-3 min-h-[60vh] ">
          <div className="stat-value text-sm  pb-2">
            Finished Content
          </div>

          {dataFetched && (
            <CurrenltyWatchingTabs finished={true}></CurrenltyWatchingTabs>
          )}
        </div>
        <div className="w-full bg-base-200 rounded-xl p-3 min-h-[60vh] ">
          <div className="stat-value text-sm pb-2">
            Currenlty Learning
          </div>

          {dataFetched && (
            <CurrenltyWatchingTabs finished={false}></CurrenltyWatchingTabs>
          )}
        </div>
      </div>
    </div>
  );
}

function StatBadge(props: {
  title: string;
  value: string;
  description?: any;
  icon?: any;
  loading?: boolean;
}) {
  return (
    <div className="bg-base-200">
      <div className="stat 2xl:w-[16rem] w-[100%]">
        <div className="stat-figure w-8 h-8">{props.icon}</div>
        <div className="stat-title sm:text-sm text-xs capitalize">
          {props.title}
        </div>
        {props.loading ? (
          <div className="stat-value h-10 flex items-center">
            <span className="h-7 loading loading-spinner"></span>
          </div>
        ) : (
          <div className="stat-value md:text-4xl text-lg">{props.value}</div>
        )}
        <div className="stat-desc text-xs">{props.description}</div>
      </div>
    </div>
  );
}

function CountStat(props: { content: string; icon: any; description: string }) {
  const params = useParams();

  const [{ data, loading, error }] = useAxios({
    url: `users/${params.memberId}/stats`,
    params: {
      finished: true,
      content: props.content,
    },
  });

  return (
    <StatBadge
      title={props.content}
      loading={loading}
      value={data?.totalDocs}
      description={props.description}
      icon={props.icon}
    ></StatBadge>
  );
}

function TotalWatchTime() {
  const params = useParams();

  const [{ data, loading, error }] = useAxios({
    url: `users/${params.memberId}/totalWatchTime`,
  });

  return (
    <StatBadge
      title={"Watch Time"}
      loading={loading}
      value={data?.total === 0 ? "0" : secondsToHoursAndMinutes(data?.total)}
      description={"total watch time"}
      icon={<ClockIcon className="h-7"></ClockIcon>}
    ></StatBadge>
  );
}

function BadgesCarousel(props: { openBadgeHandler: any }) {
  const params = useParams();

  const [{ data, loading }] = useAxios({
    url: `users/${params.memberId}/claimedBadges`,
  });

  if (loading) {
    return (
      <Carousel arrows={false} responsive={responsive}>
        {[1, 2, 3, 4].map((i) => {
          return (
            <div key={i} className="animate-pulse">
              <div className="h-[12rem] ml-2 mr-2 bg-base-300 rounded-xl"></div>
            </div>
          );
        })}
      </Carousel>
    );
  }

  if (data?.search?.totalDocs === 0) {
    return (
      <div className="flex items-center justify-center">
        <div className="text-sm p-2 text-white text-opacity-50">
          No badges yet. Finish learning
        </div>
      </div>
    );
  }

  return (
    <Carousel
      customLeftArrow={
        <div className="absolute top-0 h-full flex items-center left-0 p-2 hover:bg-zinc-950 hover:bg-opacity-50 cursor-pointer">
          <ArrowLeftIcon className="cursor-pointer text-primary-400 h-5 " />
        </div>
      }
      customRightArrow={
        <div className="absolute top-0 h-full flex items-center right-0 p-2 hover:bg-zinc-950 hover:bg-opacity-50 cursor-pointer">
          <ArrowRightIcon className=" text-primary-400 h-5 " />
        </div>
      }
      className=""
      responsive={responsive}
    >
      {data?.map((badge: any) => {
        return (
          <div
            key={badge?._id}
            onClick={() => props.openBadgeHandler(badge?.badge)}
            className="ml-2 mr-2 h-full cursor-pointer"
          >
            <Badge
              id={badge?.badge?._id as string}
              name={badge?.badge?.name as string}
              description={badge?.badge?.description as string}
            ></Badge>
          </div>
        );
      })}
    </Carousel>
  );
}

export function Badge(props: {
  id?: string;
  name?: string;
  description?: string;
  focused?: boolean;
}) {
  return (
    <div
      className={`card h-full card-side shadow-none border border-base-300 hover:bg-base-300 cursor-pointer bg-base-200 ${
        props.focused ? "scale-150" : ""
      }`}
    >
      <figure className="w-40 p-4">
        <img
          src={`https://static.socora.com/img/badge/${props.id}.png`}
          alt="Badge"
        />
      </figure>
      <div className="card-body p-4 pl-0 w-[20rem]">
        <h2 className="sm:block hidden text-sm stat-value">{props.name}</h2>
        <h2 className="sm:hidden block text-sm">{props.name}</h2>
        <p className="text-xs ">
          {props.description}
        </p>
      </div>
    </div>
  );
}

export function BadgeDialog(props: {
  badge: any;
  open: boolean;
  isFinish?: boolean;
  onClose?: any;
}) {
  return (
    <dialog
      id="my_modal_1"
      open={props.open}
      className="modal bg-black bg-opacity-50"
    >
      <form method="dialog" className="modal-box">
        <div className="flex flex-col gap-2">
          {props.isFinish ? (
            <div className="flex gap-4">
              <div>🎉</div>
              <div className="stat-value text-lg">
                Congrats you have earned a new badge!
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="flex items-center justify-center">
            <img
              className="h-56"
              src={`https://static.socora.com/img/badge/${props?.badge?._id}.png`}
            ></img>
          </div>

          <p className="stat-value text-lg">{props?.badge?.name}</p>

          <p className=" text-sm">
            {props?.badge?.description}
          </p>
        </div>

        <div className="modal-action">
          <button onClick={() => props.onClose()} className="btn">
            Close
          </button>
        </div>
      </form>
    </dialog>
  );
}
