import { Link } from "react-router-dom";

export function AuthLink(props: {
  path: string;
  label: string;
  message: string;
}) {
  return (
    <div className="text-sm opacity-80">
      {props.message}{" "}
      <Link className="link link-info" to={props.path}>
        {props.label}
      </Link>
    </div>
  );
}
