import { Outlet } from "react-router-dom";
import LoginSVG from "./LoginSVG";
import ThemeToggler from "../../components/input/ThemeToggler";

export default function Auth() {
  return (
    <div className="w-screen h-screen">
      <div className="flex w-full h-full">
        <div className="w-1/2 h-full ">
          <Outlet />
        </div>
        <div className="w-1/2 h-full ">
          <div className="flex flex-col h-full ">
          <div className="ml-auto p-4"><ThemeToggler></ThemeToggler></div>
            <div className=" h-full flex items-center justify-center ">
              <p className="font-bold text-4xl">
                <span className="text-primary">Socora</span> <span>Business</span>
              </p>
            </div>
            <div className="mt-auto">
              <LoginSVG></LoginSVG>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
