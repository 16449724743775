import { useForm, SubmitHandler } from "react-hook-form";
import useAxios from "axios-hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { PencilIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { UserContext } from "../../App";

type Inputs = {
  currentPassword: string;
  newPassword: string;
  code: number;
};

export default function ChangePassword() {

  const [isEdit, setIsEdit] = useState(false);

  const userContext = useContext(UserContext);

  const [{ data, loading, error }, getMfa] = useAxios(
    {
      url: "/auth/mfa",
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    if (isEdit) {
      console.log('here');
      
      getMfa().then(r => {

      }).catch();
    }
  }, [isEdit]);


  if (isEdit) {
    return (
      <div className="card bg-base-200">
        <div className="card-body ">
          <div className="flex">
            <button
              onClick={() => {
                setIsEdit(false);
              }}
              className="btn btn-circle btn-sm ml-auto btn-ghost text-primary"
            >
              <XMarkIcon></XMarkIcon>
            </button>
          </div>
          <div className="">
            {
              data ? (
                <ChangePasswordForm mfaEnabled={data.enabled}></ChangePasswordForm>
              ): <div className="flex items-center justify-center"><span className="loading loading-spinner"></span></div>
            }
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="card bg-base-200">
      <div className="card-body ">
        <div className="flex items-center">
          <div className="text-sm">Last updated at: </div>
          <div className="ml-auto">
            <button
              onClick={() => setIsEdit(true)}
              className="btn btn-ghost btn-sm text-primary"
            >
              Change Password
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function ChangePasswordForm(props: { mfaEnabled: boolean }) {
  const navigate = useNavigate();
  const { state } = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const [{ data, loading, error }, changePassword] = useAxios(
    {
      url: "/auth/password/change",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      let response = await changePassword({
        data: {
          ...data,
          code: (data.code) ? data.code : 123123
        },
      });
    } catch (error) {}
  };

  return (
    <div className="flex items-center justify-center flex-col h-full gap-4">
      <form className="flex flex-col w-1/3" onSubmit={handleSubmit(onSubmit)}>
        <label className="form-control w-full ">
          <div className="label">
            <span className="label-text">Current Password</span>
          </div>
          <input
            disabled={loading}
            {...register("currentPassword", {
              required: true,
            })}
            type="password"
            className="input input-bordered input-sm w-full"
          />
          <div className="label">
            {errors?.currentPassword?.type === "required" && (
              <span className="label-text-alt text-error">
                This field is required
              </span>
            )}
          </div>
          <div className="label">
            <span className="label-text">New Password</span>
          </div>
          <input
            disabled={loading}
            {...register("newPassword", { required: true })}
            type="password"
            className="input input-bordered input-sm w-full"
          />
          <div className="label">
            {errors?.newPassword?.type === "required" && (
              <span className="label-text-alt text-error">
                This field is required
              </span>
            )}
          </div>
          {props.mfaEnabled && (
            <>
              <div className="label">
                <span className="label-text">Code</span>
              </div>
              <input
                disabled={loading}
                {...register("code", {
                  required: true,
                  maxLength: 6,
                  minLength: 6,
                })}
                className="input input-bordered input-sm w-full text-center font-bold"
              />
              <div className="label">
                {errors?.code?.type === "required" && (
                  <span className="label-text-alt text-error">
                    This field is required
                  </span>
                )}
                {(errors?.code?.type === "maxLength" ||
                  errors?.code?.type === "minLength") && (
                  <span className="label-text-alt text-error">
                    Incorrect code length
                  </span>
                )}
              </div>
            </>
          )}
        </label>
        <div className="pt-2 w-full">
          <button
            className="btn btn-sm w-full btn-primary"
            disabled={loading}
            type="submit"
          >
            Change Password
          </button>
        </div>
        {error && (
          <div className="alert alert-error text-sm mt-6 text-center">
            {error?.response?.data?.message}
          </div>
        )}
        {loading && (
          <div className="flex items-center justify-center mt-6">
            <div className="loading loading-spinner "></div>
          </div>
        )}
        {!loading && data && (
          <div className="alert alert-success mt-6">
            Changed Password successfully
          </div>
        )}
      </form>
    </div>
  );
}
