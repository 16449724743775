import {
  ArrowLeftIcon,
  ArrowRightIcon,
  BanknotesIcon,
  UserGroupIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";

const navMenu = [
 
  {
    label: "Members",
    route: "/members",
    icon: <UserGroupIcon className="h-5 w-5" />,
    submenu: [],
  },
  // {
  //   label: "Subscription",
  //   route: "/subscription",
  //   icon: <BanknotesIcon className="h-5 w-5" />,
  //   submenu: [],
  // },
  {
    label: "Account",
    route: "/account/subscription",
    icon: <UserIcon className="h-5 w-5" />,
    submenu: [],
  },
  
];

export default function NavbarLeft(props: {
  onCollapseChange: any;
  isCollapsed: boolean;
}) {

  const location = useLocation();

  return (
    <>
      <div className="min-h-[4rem] flex items-center justify-center">
        {!props.isCollapsed ? (
          <img
            className="w-40 "
            src="/socora_logo_text.png"
          ></img>
        ) : (
          <img
            className="w-7 "
            src="/socora_logo.png"
          ></img>
        )}
      </div>
      <ul className="menu rounded-box" style={{ height: "calc(100% - 4rem)" }}>
        {navMenu
         
          .map((item, i) =>
            item.submenu?.length ===
            0 ? (
              <li key={i} className="">
                <Link
                  to={item.route}
                  className={
                    item.route.startsWith('/' + location.pathname.split('/')[1])
                      ? "text-primary bg-base-100"
                      : ""
                  }
                >
                  {item.icon}
                  {!props.isCollapsed ? item.label : ""}
                </Link>
              </li>
            ) : props.isCollapsed ? (
              item.submenu
                .map((subitem:any, i) => (
                  <li key={i}>
                    <Link
                      to={subitem.route}
                      className={
                        location.pathname.endsWith(subitem.route)
                          ? "text-primary"
                          : ""
                      }
                    >
                      {subitem.icon}
                      {!props.isCollapsed ? subitem.label : ""}
                    </Link>
                  </li>
                ))
            ) : (
              <li key={i}>
                <details open={location.pathname.startsWith(item.route)}>
                  <summary>
                    {item.icon}
                    {!props.isCollapsed ? item.label : ""}
                  </summary>
                  <ul>
                    {item.submenu
                     
                      .map((subitem:any, i) => (
                        <li key={i}>
                          <Link
                            to={subitem.route}
                            className={
                              location.pathname.startsWith(subitem.route)
                                ? "text-primary"
                                : ""
                            }
                          >
                            {subitem.icon}
                            {!props.isCollapsed ? subitem.label : ""}
                          </Link>
                        </li>
                      ))}
                  </ul>
                </details>
              </li>
            )
          )}

        <li className="mt-auto">
          <a
            className=""
            onClick={() => {
              props.onCollapseChange(!props.isCollapsed);
            }}
          >
            {!props.isCollapsed ? (
              <>
                <ArrowLeftIcon className="h-5 w-5"></ArrowLeftIcon>
                Collapse
              </>
            ) : (
              <>
                <ArrowRightIcon className="h-5 w-5"></ArrowRightIcon>
              </>
            )}
          </a>
        </li>
      </ul>
    </>
  );
}
