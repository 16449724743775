import { UserGroupIcon, UserIcon } from "@heroicons/react/24/outline";
import { useEffect } from "react";
import { Link, Outlet, useLocation, useOutletContext } from "react-router-dom";

const tabs = [
  {
    label: "Subscription",
    pathname: "subscription",
  },
  {
    label: "Info",
    pathname: "info",
  },
  {
    label: "Settings",
    pathname: "settings",
  },
];

export default function Account(props: {}) {
  const location = useLocation();

  const { setTitleComponent } = useOutletContext() as any;

  useEffect(() => {
    setTitleComponent(
      <div className="flex items-center justify-center gap-2">
        <UserIcon className="h-8 w-8"></UserIcon>
        <div>Account</div>
      </div>
    );
  }, []);

  return (
    <div>
      <div className="bg-base-200 border-t-[1px] border-base-100">
        <div
          role="tablist"
          className="tabs tabs-bordered w-[30%] tab-border-none"
        >
          {tabs.map((tab, i) => (
            <Link
              to={tab.pathname}
              role="tab"
              key={i}
              className={`tab ${
                tab.pathname === location.pathname.split("/")[2]
                  ? "tab-active"
                  : ""
              }`}
            >
              {tab.label}
            </Link>
          ))}
        </div>
      </div>
      <div className="p-5">
        <Outlet></Outlet>
      </div>
    </div>
  );
}
