import useAxios from "axios-hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthLink } from "./components/AuthLink";
import { useEffect } from "react";

export default function SignUpConfirm(props: {}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [{ data, loading, error }, confirmSignUp] = useAxios(
    {
      url: "/auth/signup/confirm",
      method: "POST",
      data: {
        code: searchParams.get("code"),
        sub: searchParams.get("sub"),
        accountType: "BUSINESS"
      },
    },
    { manual: true }
  );

  useEffect(() => {
    confirmSignUp()
      .then((response) => {
        navigate("/auth/login", {
          state: {
            confirmed: true,
            message: "Your account has been successfully confirmed! Please proceed to log in."
          }
        });
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <div className="h-full flex items-center justify-center flex-col">
      <div className="w-1/2 flex items-center justify-center flex-col gap-3">
        {loading ? (
          <>
            <div className="font-bold">Verifing email</div>
            <div className="loading loading-spinner"></div>
          </>
        ) : (
          error && (
            <>
              <div className="alert alert-error">
                {error?.response?.data?.message}
              </div>
              <AuthLink
                label="Resend Code"
                message="Looks like the link is incorrect!"
                path="/auth/signup"
              ></AuthLink>
            </>
          )
        )}
      </div>
    </div>
  );
}
