import { useForm, SubmitHandler } from "react-hook-form";
import { AuthLink } from "./components/AuthLink";
import useAxios from "axios-hooks";
import { useLocation, useNavigate } from "react-router-dom";

type Inputs = {
  username: string;
  password: string;
};

export default function Login() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const [{ data, loading, error }, login] = useAxios(
    {
      url: "/auth/login",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      let response = await login({
        data: data,
      });

      if (response.status === 200) {
        if (response.data.mfa) {
          navigate("/auth/login/mfa");
        } else {
          navigate("/");
        }
      }
    } catch (error) {}
  };

  return (
    <div className="flex items-center justify-center flex-col h-full gap-4">
      <form className="flex flex-col w-1/2" onSubmit={handleSubmit(onSubmit)}>
        <label className="form-control w-full">
          <div className="label">
            <span className="label-text">Username</span>
          </div>
          <input
            disabled={loading}
            {...register("username", {
              required: true,
            })}
            type="text"
            className="input input-bordered input-sm w-full"
          />
          <div className="label">
            {errors?.username?.type === "required" && (
              <span className="label-text-alt text-error">
                This field is required
              </span>
            )}
          </div>
          <div className="label">
            <span className="label-text">Password</span>
          </div>
          <input
            disabled={loading}
            {...register("password", { required: true })}
            type="password"
            className="input input-bordered input-sm w-full"
          />
          <div className="label">
            {errors?.password?.type === "required" && (
              <span className="label-text-alt text-error">
                This field is required
              </span>
            )}
          </div>
        </label>
        <div className="pt-2 w-full">
          <button
            className="btn btn-sm w-full btn-primary"
            disabled={loading}
            type="submit"
          >
            Login
          </button>
        </div>
        {error && (
          <div className="alert alert-error text-sm mt-6 text-center">
            {error?.response?.data?.message}
            {error?.response?.data?.__type === "UserNotConfirmedException" && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                }}
                className="btn btn-xs ml-auto"
              >
                Resend code
              </button>
            )}
          </div>
        )}
        {loading && (
          <div className="flex items-center justify-center mt-6">
            <div className="loading loading-spinner "></div>
          </div>
        )}
        {state?.confirmed && (
          <div className="alert alert-success text-sm mt-6 text-center">
            {state?.message}
          </div>
        )}
      </form>

      <div className="flex flex-col gap-2 items-center">
        <AuthLink
          label="Sign Up"
          message="You dont have an account?"
          path="/auth/signup"
        ></AuthLink>
        <AuthLink
          label="Forgot Password"
          message="You forgot your password?"
          path="/auth/forgot-password"
        ></AuthLink>
      </div>
    </div>
  );
}

// export function LoginForm() {
//   const {
//     register,
//     handleSubmit,
//     watch,
//     formState: { errors },
//   } = useForm<Inputs>();

//   const onSubmit: SubmitHandler<Inputs> = (data) => console.log(data);
//   console.log(errors);

//   return (
//     <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
//       <label className="form-control w-full">
//         <div className="label">
//           <span className="label-text">Username</span>
//         </div>
//         <input
//           {...register("username", {
//             required: true,
//             pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i,
//           })}
//           type="text"
//           className="input input-bordered input-sm w-full"
//         />
//         <div className="label">
//           {errors?.username?.type === "required" && (
//             <span className="label-text-alt text-error">
//               This field is required
//             </span>
//           )}

//         </div>
//       </label>
//       <label className="form-control w-full">
//         <div className="label">
//           <span className="label-text">Password</span>
//         </div>
//         <input
//           {...register("password", { required: true })}
//           type="text"
//           className="input input-bordered input-sm w-full"
//         />
//         <div className="label">
//           {errors?.password?.type === "required" && (
//             <span className="label-text-alt text-error">
//               This field is required
//             </span>
//           )}
//         </div>
//       </label>
//       <div className="pt-2 w-full">
//       <button className="btn btn-sm w-full" type="submit">
//         Login
//       </button>
//       </div>
//     </form>
//   );
// }
