
import { Link, useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ClockIcon } from "@heroicons/react/24/outline";
import useAxios from "axios-hooks";
import { finished } from "stream";
import ContentTableSkeleton from "./ContentTableSkeleton";
import ContentTable from "./ContentTable";

export function CurrenltyWatchingContent(props: { content: string, finished?: boolean}) {
  const params = useParams();

  const [{ data, loading }] = useAxios({
    url: `users/${params.memberId}/stats`,
    params: {
      finished: props.finished,
      content: props.content,
    },
  });

  if (loading) {
    return <ContentTableSkeleton rows={5}></ContentTableSkeleton>;
  }

  if (data?.totalDocs === 0) {
    return (
      <div className="w-full p-10">
        <div className="h-full w-full sm:text-sm text-xs flex items-center justify-center text-white text-opacity-50">
          Nothing here yet.
        </div>
      </div>
    );
  }

  return <ContentTable data={data.docs} contentType={props.content}></ContentTable>;
}


export function CurrenltyWatchingTabs(props: {
  finished: boolean
}) {
  const [currentTab, setCurrentTab] = useState("course");
  return (
    <div className="">
      <div
          role="tablist"
          className="tabs tabs-bordered w-[20%] tab-border-none"
        >
          {["course", "lab", "path"].map((tab, i) => (
            <div
             onClick={() => setCurrentTab(tab)}
              role="tab"
              key={i}
              className={`tab capitalize ${currentTab === tab && 'tab-active font-bold'}`}
            >
              {tab}s
            </div>
          ))}
        </div>
        
      <CurrenltyWatchingContent
        content={currentTab}
        finished={props.finished}
      ></CurrenltyWatchingContent>
    </div>
  );
}

// export default function CurrenltyWatchingDropdown() {
//   const location = useLocation();

//   const [currentlyWatchingOpen, setCurrentlyWatchingOpen] = useState(false);

//   useEffect(() => {
//     setCurrentlyWatchingOpen(false);
//   }, [location]);

//   return (
//     <div className="dropdown dropdown-end dropdown-open">
//       <button
//         onClick={() => setCurrentlyWatchingOpen(!currentlyWatchingOpen)}
//         tabIndex={0}
//         className={`btn btn-ghost ml-auto ${
//           currentlyWatchingOpen ? "text-primary" : ""
//         }`}
//       >
//         <ClockIcon className="h-4"></ClockIcon>
//       </button>
//       {currentlyWatchingOpen ? (
//         <div
//           tabIndex={0}
//           className="p-0 menu dropdown-content shadow-xl bg-base-100 rounded-box mt-3 sm:w-[30rem] w-[15rem] h-[35rem] overflow-scroll"
//         >
//           <CurrenltyWatchingTabs></CurrenltyWatchingTabs>
//         </div>
//       ) : (
//         ""
//       )}
//     </div>
//   );
// }
