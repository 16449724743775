import useAxios from "axios-hooks";
import { UserResDto, UsersResDto } from "../../api/Api";
import { MembersTable } from "../../pages/members/Members";
import { useEffect, useState } from "react";
import AutocompleteInput from "../input/AutoCompleteInput";
import { XMarkIcon } from "@heroicons/react/24/outline";

export default function UserSelectionModal(props: {requiredDisableCount: number, onComplete: any}) {
  const [searchString, setSearchString] = useState(undefined as any);
  const [statusChangeUserId, setStatusChangeUserId] = useState(
    undefined as any
  );

  const [{ data, loading, error }, getMembers] = useAxios<UsersResDto>({
    url: "/users",
    params: {
      search: searchString,
    },
  });

  const [
    {
      data: changeUserStatusData,
      loading: changeUserStatusLoading,
      error: changeUserStatusError,
    },
    changeUserStatus,
  ] = useAxios<UsersResDto>(``, {
    manual: true,
  });

  const handleDeactivateUser = async (userId: string) => {
    try {
      setStatusChangeUserId(userId);
      const user = await changeUserStatus({
        url: `/users/${userId}/status`,
        method: "POST",
        data: {
          status: "DISABLE"
        }
      });
      getMembers();
      setStatusChangeUserId("");
    } catch (error) {}
  };

  useEffect(() => {
    getMembers();
  }, [searchString]);

  return (
    <div className="">
      {/* Open the modal using document.getElementById('ID').showModal() method */}
      <button
        className="btn btn-primary btn-sm"
        onClick={() =>
          (document?.getElementById("selection_modal") as any)?.showModal()
        }
      >
        Select Members
      </button>
      <dialog onClose={() => props.onComplete()}  id="selection_modal" className="modal">
        <div className="modal-box max-w-none">
          <h3 className="font-bold text-lg">Disable Members</h3>
          <div className="flex">
            <div className="w-1/2 pb-2">
              <AutocompleteInput
                onComplete={(input: string) => {
                  setSearchString(input);
                }}
                placeHolder="Search..."
                className="bg-base-200"
              ></AutocompleteInput>
            </div>
          </div>

          {!loading ? (
            <table className="table bg-base-200">
              <thead>
                <tr>
                  <td>Username</td>
                  <td>Email</td>
                  <td>Active</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {data?.docs?.map((member) => {
                  return (
                    <tr
                      key={member?._id}
                      className="hover:bg-base-300 cursor-pointer border-base-100"
                    >
                      <td>{member?.username}</td>
                      <td>{member?.email}</td>
                      <td>
                        {member?.enabled ? (
                          <div className="badge badge-success">active</div>
                        ) : (
                          <div className="badge badge-error">disabled</div>
                        )}
                      </td>
                      <td className="flex">
                        {member?.enabled &&  (
                          <button
                          disabled={changeUserStatusLoading}
                            onClick={() => {
                              handleDeactivateUser(member._id);
                            }}
                            className="btn btn-sm btn-ghost text-error ml-auto"
                          >
                            <XMarkIcon className="h-4"></XMarkIcon>
                            Disable
                          </button>
                        )}
                        {
                          (statusChangeUserId === member._id) && changeUserStatusLoading && (
                            <span className="loading loading-spinner"></span>
                          )
                        }
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <h1>Loading...</h1>
          )}
          <div className="modal-action">
            <form method="dialog">
              <button className="btn btn-sm">Close</button>
            </form>
          </div>
        </div>
      </dialog>
    </div>
  );
}
