import {
  InformationCircleIcon,
  PlusIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import useAxios from "axios-hooks";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import MemberForm from "./form/MemberForm";
import { CreateUserDto, UserResDto, UsersResDto } from "../../api/Api";
import AutocompleteInput from "../../components/input/AutoCompleteInput";

export function MembersTable(props: { data: UserResDto[] | undefined }) {
  const navigate = useNavigate();

  return (
    <div className="overflow-x-auto">
      <table className="table table-md bg-base-200">
        <thead>
          <tr>
            <th></th>
            <td>Username</td>
            <td>Email</td>
            <td>Active</td>
          </tr>
        </thead>
        <tbody>
          {props?.data?.map((member, index) => {
            return (
              <tr
                key={member?._id}
                className="hover:bg-base-300 cursor-pointer border-base-100"
                onClick={() => {
                  navigate(member?._id + "/stats");
                }}
              >
                <td>{index}</td>
                <td>{member?.username}</td>
                <td>{member?.email}</td>

                <td>
                  {member?.enabled ? (
                    <div className="badge badge-success">active</div>
                  ) : (
                    <div className="badge badge-error">disabled</div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default function Members() {
  const { setTitleComponent } = useOutletContext() as any;
  const navigate = useNavigate();

  const [searchString, setSearchString] = useState(undefined as any);

  const [{ data, loading, error }, getMembers] = useAxios<UsersResDto>(
    {
      url: "/users",
      params: {
        search: searchString,
      },
    },
  );

  const [
    {
      data: createMemberData,
      loading: createMemberLoading,
      error: createMemberError,
    },
    createMember,
  ] = useAxios<UserResDto, CreateUserDto>(
    {
      url: "/users",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    setTitleComponent(
      <div className="flex items-center justify-center gap-2">
        <UserGroupIcon className="h-8 w-8"></UserGroupIcon>
        Members
      </div>
    );
    if (searchString !== undefined) {
      getMembers();
    }
  }, [searchString]);

  return (
    <div className="flex flex-col gap-2 p-5">
      <div className="flex">
        <div>
          <AutocompleteInput
            onComplete={(input: string) => {
              setSearchString(input);
            }}
            placeHolder="Search..."
            className="bg-base-200"
          ></AutocompleteInput>
        </div>
        <div className="ml-auto">
          <button
            className="btn btn-sm"
            onClick={() => {
              (
                document?.getElementById("new_member_modal") as any
              )?.showModal();
            }}
          >
            <PlusIcon className="h-4"></PlusIcon>
            New Member
          </button>
        </div>
      </div>
      {!loading ? (
        <MembersTable data={data?.docs}></MembersTable>
      ) : (
        <h1>Loading</h1>
      )}

      <dialog id="new_member_modal" className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg pb-2">Create Member</h3>
          <div>
            <MemberForm
              onSuccess={(user: UserResDto) => {
                navigate(`${user?._id}/stats`);
              }}
            ></MemberForm>
          </div>
          {/* <div className="alert text-xs italic bg-transparent bg-base-100 border-0 opacity-50 p-0 mt-4">
            <InformationCircleIcon className="h-6"></InformationCircleIcon>
            You will be charged automatically for the new Member and an
            invitaion email with a temporary password will be sent to the member
            that you add.
          </div> */}
        </div>
      </dialog>
    </div>
  );
}
