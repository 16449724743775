export default function ConfirmationButton(props: {
  id: string;
  buttonText: string;
  body: string;
  title: string;
  onAccept: any;
  onReject: any;
  className: string;
  icon?: any;
}) {
  return (
    <>
      <button
        className={props.className}
        onClick={() => {
          (document.getElementById(props.id) as any).showModal();
        }}
      >
        {props?.icon}
        {props.buttonText}
      </button>
      <dialog id={props.id} className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">{props.title}</h3>
          <p className="py-4">{props.body}</p>
          <div className="modal-action">
            <form method="dialog" className="flex gap-4">
              <button
                onClick={() => props.onAccept()}
                className="btn btn-sm btn-error"
              >
                Yes
              </button>
              <button
                onClick={() => props.onReject()}
                className="btn btn-sm btn-success"
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      </dialog>
    </>
  );
}
