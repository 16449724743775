import { useState } from "react";

export default function AutocompleteInput(props: {
  onComplete: any;
  placeHolder: string;
  className?: string;
  value?: string;
}) {
  const [searchLoading, setSearchLoading] = useState(false);
  const [currentTimeout, setCurrentTimeout] = useState(undefined as any);

  const onSearchHandler = (searchString: any) => {
    setSearchLoading(true);
    if (currentTimeout) clearTimeout(currentTimeout);
    let timeout = setTimeout(() => {
      props.onComplete(searchString);
      setSearchLoading(false);
    }, 500);
    setCurrentTimeout(timeout);
  };

  return (
    <div className="w-full h-[2rem]">
      <div className="w-full h-full flex border border-white border-opacity-5 rounded-lg ">
        <div className="w-full h-full ">
          <input
            defaultValue={props?.value}
            type="text"
            onChange={(event) => onSearchHandler(event.target.value)}
            placeholder={props.placeHolder}
            className={`input placeholder:text-white placeholder:text-opacity-50 w-full h-full rounded-r-none focus:outline-none focus:border-none text-sm ${props.className}`}
          />
        </div>
        <div
          className={`w-1/12 flex items-center justify-center rounded-r-lg bg-base-100  ${props.className}`}
        >
          {searchLoading ? (
            <span className="loading loading-spinner loading-md"></span>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
