import {
  ArrowDownOnSquareIcon,
  ExclamationCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import useAxios from "axios-hooks";
import * as QRCode from "qrcode";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../App";
import { SubmitHandler, useForm } from "react-hook-form";

export default function MFA() {
  const [isEdit, setIsEdit] = useState(false);

  const [{ data, loading, error }, refetch] = useAxios({
    url: "/auth/mfa",
  });

  if (isEdit) {
    return (
      <div className="card bg-base-200">
        <div className="card-body">
          <div className="flex">
            <button
              onClick={() => {
                setIsEdit(false);
                refetch();
              }}
              className="btn btn-circle btn-sm ml-auto btn-ghost text-primary"
            >
              <XMarkIcon></XMarkIcon>
            </button>
          </div>
          <div className="flex items-center justify-center">
            <div className="w-1/3">
              {!data.enabled && (
                <MFAInit
                  onFinish={() => {
                    setIsEdit(false);
                    refetch();
                  }}
                ></MFAInit>
              )}
              {data.enabled && (
                <VerifyMFACode
                  url="/auth/mfa/disable"
                  buttonText="Disable"
                  formText="Please enter a valid MFA code in order to disable MFA for this account"
                  onFinish={() => {
                    setIsEdit(false);
                    refetch();
                  }}
                ></VerifyMFACode>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (loading) {
    return <span className="loading loading-spinner"></span>;
  }

  return (
    <div className="card bg-base-200">
      <div className="card-body">
        <div className="flex items-center">
          {!data?.enabled && (
            <>
              <div className="alert text-warning text-sm">
                <ExclamationCircleIcon className="h-5"></ExclamationCircleIcon>
                Multi Factor authentication is disabled.
              </div>

              <button
                onClick={() => setIsEdit(true)}
                className="btn btn-sm btn-ghost text-primary w-auto btn-primary ml-auto"
              >
                Enable MFA
              </button>
            </>
          )}

          {data?.enabled && (
            <>
              <div className="alert text-success text-sm">
                <ExclamationCircleIcon className="h-5"></ExclamationCircleIcon>
                Multi Factor authentication is enabled.
              </div>

              <button
                onClick={() => setIsEdit(true)}
                className="btn btn-sm btn-ghost text-primary w-auto btn-primary ml-auto"
              >
                Disable MFA
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export function MFAEdit() {
  const [init, setInit] = useState(false);
  const [isDisable, setIsDisable] = useState(false);

  const [{ data, loading, error }, refetch] = useAxios({
    url: "/auth/mfa",
  });

  if (loading) {
    return <span className="loading loading-spinner"></span>;
  }

  if (init) {
    return (
      <div className="flex flex-col gap-2 w-1/3">
        <button
          onClick={() => setInit(false)}
          className="btn btn-sm btn-ghost ml-auto "
        >
          <XMarkIcon className="h-4"></XMarkIcon>
          Close
        </button>
        <MFAInit
          onFinish={() => {
            setInit(false);
            refetch();
          }}
        ></MFAInit>
      </div>
    );
  }

  if (isDisable) {
    return (
      <div className="flex flex-col gap-2 w-1/3">
        <VerifyMFACode
          url="/auth/mfa/disable"
          buttonText="Disable"
          formText="Please enter a valid MFA code in order to disable MFA for this account"
          onFinish={() => {
            setIsDisable(false);
            refetch();
          }}
        ></VerifyMFACode>
      </div>
    );
  }

  return (
    <div>
      {!data?.enabled && (
        <div className="flex flex-col">
          <div>
            <div className="alert text-warning text-sm">
              <ExclamationCircleIcon className="h-5"></ExclamationCircleIcon>
              Multi Factor authentication is not enabled.
            </div>
          </div>
          <div>
            <button
              onClick={() => setInit(true)}
              className="btn btn-sm btn-ghost text-primary w-auto btn-primary"
            >
              Enable MFA
            </button>
          </div>
        </div>
      )}

      {data?.enabled && (
        <div className="flex items-center">
          <div className="alert text-success text-sm">
            <ExclamationCircleIcon className="h-5"></ExclamationCircleIcon>
            Multi Factor authentication is enabled.
          </div>

          <button
            onClick={() => setIsDisable(true)}
            className="btn btn-sm btn-ghost text-primary w-auto btn-primary ml-auto"
          >
            Disable MFA
          </button>
        </div>
      )}
    </div>
  );
}

type Inputs = {
  code: number;
};

function VerifyMFACode(props: {
  onFinish: any;
  url: string;
  formText: string;
  buttonText: string;
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const [{ data, loading, error }, verifyMFA] = useAxios(
    {
      url: props.url,
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      let response = await verifyMFA({
        data: data,
      });
      if (response.status === 200) {
        props.onFinish();
      }
    } catch (error) {}
  };

  return (
    <div className="flex items-center justify-center flex-col h-full gap-4">
      <div className="alert text-sm bg-base-300">
        <ExclamationCircleIcon className="h-5"></ExclamationCircleIcon>
        {props.formText}
      </div>
      <form className="flex flex-col w-full" onSubmit={handleSubmit(onSubmit)}>
        <label className="form-control w-full">
          <div className="label">
            <span className="label-text">Code</span>
          </div>
          <input
            disabled={loading}
            {...register("code", {
              required: true,
              maxLength: 6,
              minLength: 6,
            })}
            className="input input-bordered input-sm w-full text-center font-bold"
          />
          <div className="label">
            {errors?.code?.type === "required" && (
              <span className="label-text-alt text-error">
                This field is required
              </span>
            )}
            {(errors?.code?.type === "maxLength" ||
              errors?.code?.type === "minLength") && (
              <span className="label-text-alt text-error">
                Incorrect code length
              </span>
            )}
          </div>
        </label>
        <div className="pt-2 w-full">
          <button
            className="btn btn-sm w-full btn-primary"
            disabled={loading}
            type="submit"
          >
            {props.buttonText}
          </button>
        </div>
        {error && (
          <div className="alert alert-error text-sm mt-6 text-center">
            {error?.response?.data?.message}
          </div>
        )}
        {loading && (
          <div className="flex items-center justify-center mt-6">
            <div className="loading loading-spinner "></div>
          </div>
        )}
      </form>
    </div>
  );
}

function MFAInit(props: { onFinish: any }) {
  const user = useContext(UserContext);

  const [{ data, loading, error }] = useAxios({
    url: "/auth/mfa/init",
    method: "POST",
  });

  const [qrURL, setQrURL] = useState("");

  useEffect(() => {
    if (!loading) {
      QRCode.toDataURL(
        `otpauth://totp/Socora(${user["cognito:username"]})?secret=${data?.SecretCode}`,
        (err, url) => {
          setQrURL(url);
        }
      );
      downloadRecoveryCodes();
    }
  }, [loading]);

  const downloadRecoveryCodes = () => {
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," +
        encodeURIComponent(data?.recoveryCodes.join("\r\n"))
    );
    element.setAttribute(
      "download",
      `Socora(${user["cognito:username"]}) Recovery Codes.txt`
    );
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  if (loading) {
    return <span className="loading loading-spinner"></span>;
  }

  return (
    <div>
      <div className="flex flex-col gap-2">
        <div className="alert text-sm bg-base-300">
          <ExclamationCircleIcon className="h-5"></ExclamationCircleIcon>
          If the download doesn't start automatically, be sure to save the
          following recovery codes. You can use them to recover your account if
          you cant access your MFA codes.
        </div>
        <div className=" p-3 bg-base-300 rounded-xl flex flex-col  gap-2 items-center justify-center">
          <div className="font-mono whitespace-pre-wrap text-center text-xs">
            {data?.recoveryCodes.map((code: string) => code + "\n")}
          </div>
          <div>
            <button
              onClick={() => downloadRecoveryCodes()}
              className="btn btn-xs btn-ghost"
            >
              <ArrowDownOnSquareIcon className="h-4"></ArrowDownOnSquareIcon>
            </button>
          </div>
        </div>
        <div className="alert bg-base-300 text-sm">
          <ExclamationCircleIcon className="h-5"></ExclamationCircleIcon>
          Scan the following QR code with your preferred authenticator app
        </div>
        <div className="bg-base-300 p-4 flex items-center justify-center rounded-xl">
          <img alt="" src={qrURL} className="rounded-xl w-[150px]"></img>
        </div>
        <div>
          <VerifyMFACode
            url="/auth/mfa/enable"
            onFinish={() => props.onFinish()}
            buttonText="Verify"
            formText=" Please provide the code generated by your preferred authenticator."
          ></VerifyMFACode>
        </div>
      </div>
    </div>
  );
}
