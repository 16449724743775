import { useForm, SubmitHandler, useWatch } from "react-hook-form";
import { CreateUserDto, UserResDto } from "../../../api/Api";
import useAxios from "axios-hooks";
import { useState } from "react";

// export type MemberInput = {
//   email: string;
//   username: string;
// };

export default function MemberForm(props: {
  onSuccess: any;
  defaultValues?: UserResDto;
}) {
  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<CreateUserDto>();

  const sendInvitation = useWatch({ control, name: "send_invitation" });

  const [
    {
      data: createMemberData,
      loading: createMemberLoading,
      error: createMemberError,
    },
    createMember,
  ] = useAxios<UserResDto, CreateUserDto>(
    {
      url: "/users",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const [
    {
      data: updateMemberData,
      loading: updateMemberLoading,
      error: updateMemberError,
    },
    updateMember,
  ] = useAxios<UserResDto, CreateUserDto>(
    {
      url: `/users/${props?.defaultValues?._id}`,
      method: "PATCH",
    },
    {
      manual: true,
    }
  );

  const onSubmit: SubmitHandler<CreateUserDto> = async (data) => {
    try {
      let response;
      if (props?.defaultValues) {
        response = await updateMember({
          data: data,
        });
      } else {
        if(data.send_invitation) {
          delete data.temporary_password;
        }
        response = await createMember({
          data: {
            ...data,
          },
        });
      }

      if (response.status === 201) {
        props.onSuccess(response.data);
      }
    } catch (error) {}
  };

  return (
    <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
      <label className="form-control w-full">
        <div className="label">
          <span className="label-text">Email</span>
        </div>

        <input
          disabled={props?.defaultValues?.email ? true : false}
          {...register("email", {
            required: true,
            pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i,
          })}
          defaultValue={props?.defaultValues?.email}
          type="text"
          className="input input-bordered input-sm w-full"
        />
        <div className="label">
          {errors?.email?.type === "required" && (
            <span className="label-text-alt text-error">
              This field is required
            </span>
          )}
          {errors?.email?.type === "pattern" && (
            <span className="label-text-alt text-error">
              Please provide a valid email
            </span>
          )}
        </div>
      </label>
      <label className="form-control w-full">
        <div className="label">
          <span className="label-text">Username</span>
        </div>
        <input
          disabled={props?.defaultValues?.username ? true : false}
          {...register("username", { required: true })}
          type="text"
          defaultValue={props?.defaultValues?.username}
          // disabled={(props?.defaultValues?.name)? true: false}
          className="input input-bordered input-sm w-full"
        />
        <div className="label">
          {errors?.username?.type === "required" && (
            <span className="label-text-alt text-error">
              This field is required
            </span>
          )}
        </div>

        {!props.defaultValues && (
          <>
            <div className="label">
              <span className="label-text">Send Invitation</span>
            </div>
            <input
              {...register("send_invitation")}
              type="checkbox"
              defaultChecked={false}
              className="checkbox checkbox-sm"
            />
            <div className="label text-sm opacity-80 italic">
              We'll send an email invitation with a temporary password to the
              member you add. If you prefer not to send an invitation, you must
              assign a temporary password to the new account.
            </div>

            {!sendInvitation && (
              <>
                <div className="label">
                  <span className="label-text">Temporary Password</span>
                </div>
                <input
                  {...register("temporary_password", { required: true })}
                  type="text"
                  className="input input-bordered input-sm w-full"
                />
                <div className="label">
                  {errors?.temporary_password?.type === "required" && (
                    <span className="label-text-alt text-error">
                      This field is required
                    </span>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </label>
      <div className="pt-2 w-full">
        <button className="btn btn-sm  btn-primary" type="submit">
          {props.defaultValues ? "Update Info" : "Create"}
        </button>
      </div>
      {createMemberError && (
        <div className="alert alert-error text-sm mt-6 text-center">
          {createMemberError?.response?.data?.message}
        </div>
      )}
      {createMemberLoading && (
        <div className="flex items-center justify-center mt-6">
          <div className="loading loading-spinner "></div>
        </div>
      )}
    </form>
  );
}
