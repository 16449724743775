import useAxios from "axios-hooks";
import { Link, useNavigate } from "react-router-dom";
import ThemeToggler from "../input/ThemeToggler";

export default function NavbarTop(props: { titleComponent?: any }) {
  const navigate = useNavigate();

  const [{ data, loading, error }, logout] = useAxios(
    {
      url: "/auth/logout",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const handleLogout = async (e: any) => {
    try {
      // e.stopPropagation();
      await logout();
      navigate("/auth/login");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="navbar z-10 bg-base-200 pl-5 pr-5">
      <div className="flex-1 font-bold text-lg">{props.titleComponent}</div>
      <ThemeToggler></ThemeToggler>
      <div className="flex-none">
        <div className="dropdown dropdown-end ">
          <div
            tabIndex={0}
            role="button"
            className="btn btn-ghost btn-circle avatar"
          >
            <div className="w-10 rounded-full">
              <img
                alt="Tailwind CSS Navbar component"
                src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairTheCaesarSidePart&accessoriesType=Wayfarers&hairColor=SilverGray&facialHairType=BeardLight&facialHairColor=Auburn&clotheType=BlazerSweater&eyeType=Happy&eyebrowType=DefaultNatural&mouthType=Eating&skinColor=Black"
              />
            </div>
          </div>
          <ul
            tabIndex={0}
            className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow-md bg-base-200 border border-base-300 rounded-box w-52"
          >
            <li>
              <Link to={"/account/settings"}>Settings</Link>
            </li>
            <li onClick={handleLogout}>
              <a className="justify-between">
                Logout
                {loading && <span className="loading loading-spinner"></span>}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
