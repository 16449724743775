import { MoonIcon, SunIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";

export default function ThemeToggler() {
  const [theme, setTheme] = useState(() => {
    const theme = localStorage.getItem("theme");
    return theme ? theme : "socora_dark";
  });

  const toggleTheme = () => {
    let newTheme = theme === "socora_dark" ? "socora_light" : "socora_dark";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  useEffect(() => {
    (document.querySelector("html") as any).setAttribute("data-theme", theme);
  }, [theme]);

  return (
    <label className={`swap  swap-rotate mr-2 `}>
      <input onClick={toggleTheme} type="checkbox" />
      {theme === "socora_dark" ? (
        <SunIcon className={`fill-current h-8 `}></SunIcon>
      ) : (
        <MoonIcon className={`fill-current h-8 `}></MoonIcon>
      )}
    </label>
  );
}
