import { useForm, SubmitHandler } from "react-hook-form";
import { AuthLink } from "./components/AuthLink";
import useAxios from "axios-hooks";
import { useLocation, useNavigate } from "react-router-dom";

type Inputs = {
  username: string;
};

export default function ForgotPassword(props: {}) {
  const navigate = useNavigate();
  const { state } = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const [{ data, loading, error }, forgotPassword] = useAxios(
    {
      url: "/auth/password/forgot",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      let response = await forgotPassword({
        data: data,
      });
      if (response.status === 200) {
        navigate("/auth/forgot-password/confirm", {
          state: {
            emailSent: true,
          },
        });
      }
    } catch (error) {}
  };

  return (
    <div className="flex items-center justify-center flex-col h-full gap-4">
      <form className="flex flex-col w-1/2" onSubmit={handleSubmit(onSubmit)}>
        <label className="form-control w-full">
          <div className="label">
            <span className="label-text">Username</span>
          </div>
          <input
            disabled={loading}
            defaultValue="vemaca1870@mnsaf.com"
            {...register("username", {
              required: true,
           
            })}
            type="text"
            className="input input-bordered input-sm w-full"
          />
          <div className="label">
            {errors?.username?.type === "required" && (
              <span className="label-text-alt text-error">
                Username is required
              </span>
            )}
          </div>
        </label>
        <div className="pt-2 w-full">
          <button
            className="btn btn-sm w-full btn-primary"
            disabled={loading}
            type="submit"
          >
            Reset Password
          </button>
        </div>
        {error && (
          <div className="alert alert-error text-sm mt-6 text-center">
            {error?.response?.data?.message}
          </div>
        )}
        {loading && (
          <div className="flex items-center justify-center mt-6">
            <div className="loading loading-spinner "></div>
          </div>
        )}
      </form>

      <div className="flex flex-col gap-2 items-center">
        <AuthLink
          label="Sign Up"
          message="You dont have an account?"
          path="/auth/signup"
        ></AuthLink>
        <AuthLink
          label="Sign In"
          message="You already have an account?"
          path="/auth/login"
        ></AuthLink>
      </div>
    </div>
  );
}
