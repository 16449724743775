import Axios from "axios";
import axiosRetry from "axios-retry";
import { configure } from "axios-hooks";

let isRefreshing = false;

const axios = Axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_HOST_URI,
});

axiosRetry(axios, {
  retries: 3,
  retryDelay: (retryCount) => {
    return 1000;
  },
  retryCondition: async (error) => {
    if (error.config?.url?.endsWith("/refresh")) return false;
    if (error.response?.status !== 401) return false;

    // refresh
    try {
      if (!isRefreshing) {
        isRefreshing = true;
        let refreshResponse = await axios("/auth/refresh", {method: "POST"});
        isRefreshing = false;
        return true;
      }
    } catch (error) {
      window.location = "/auth/login" as any;
    }

    return true;
  },
});

configure({ axios, cache: false });

export default axios;
