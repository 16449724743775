import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  RouterProvider,
  createBrowserRouter,
  redirect,
} from "react-router-dom";
import Members from "./pages/members/Members";
import { configure } from "axios-hooks";
import axios from "./api/axios-conf";
import Member from "./pages/members/Member";
import MemberStats from "./pages/members/MemberStats";
import Auth from "./pages/auth/Auth";
import Login from "./pages/auth/Login";
import SignUp from "./pages/auth/SignUp";
import SignUpConfirm from "./pages/auth/SignUpConfirm";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ForgotPasswordConfirm from "./pages/auth/ForgotPasswordConfirm";
import Account from "./pages/account/Account";
import AccountSubscription, {
  AccountIntialSubscriptionView,
} from "./pages/account/AccountSubscription";
import AccountSettings from "./pages/account/AccountSettings";
import MemberSettings from "./pages/members/MemberSettings";
import AccountInfo from "./pages/account/AccountInfo";
import LoginMFA from "./pages/auth/LoginMFA";
import RecoverMFA from "./pages/auth/RecoverMFA";

configure({ axios, cache: false });

const router = createBrowserRouter([
  {
    path: "auth",
    loader: async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_HOST_URI}/auth/user`,
          { credentials: "include" }
        );
        return response.status !== 401 ? redirect("/members") : "";
      } catch (error) {
        console.log(error);
      }
    },
    element: <Auth></Auth>,
    children: [
      {
        path: "login",
        element: <Login></Login>,
      },
      {
        path: "login/mfa",
        element: <LoginMFA />,
      },
      {
        path: "mfa/recover",
        element: <RecoverMFA />,
      },
      {
        path: "signup",
        children: [
          { path: "", element: <SignUp></SignUp> },
          { path: "confirm", element: <SignUpConfirm></SignUpConfirm> },
        ],
      },
      {
        path: "forgot-password",

        children: [
          {
            path: "",
            element: <ForgotPassword></ForgotPassword>,
          },
          {
            path: "confirm",
            element: <ForgotPasswordConfirm></ForgotPasswordConfirm>,
          },
        ],
      },
    ],
  },
  {
    path: "subscription/init",
    element: <AccountIntialSubscriptionView></AccountIntialSubscriptionView>,
  },
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        loader: () => {
          return redirect("/members");
        },
      },
      {
        path: "members",
        children: [
          {
            path: "",
            element: <Members />,
          },
          {
            path: ":memberId",
            element: <Member></Member>,
            children: [
              {
                path: "stats",
                element: <MemberStats></MemberStats>,
              },
              {
                path: "settings",
                element: <MemberSettings></MemberSettings>,
              },
            ],
          },
        ],
      },
      // {
      //   path: "subscription",
      //   element: <Subscription></Subscription>,
      // },
      {
        path: "account",
        element: <Account></Account>,
        children: [
          {
            path: "subscription",
            element: <AccountSubscription></AccountSubscription>,
          },
          {
            path: "info",
            element: <AccountInfo />,
          },
          {
            path: "settings",
            element: <AccountSettings />,
          },
        ],
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <React.StrictMode>
  <RouterProvider router={router} />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
