import useAxios from "axios-hooks";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
} from "chart.js";
import { Doughnut, PolarArea, Radar } from "react-chartjs-2";
import { useParams } from "react-router-dom";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

function prepareChartData(data: any[]) {
  let topicActivityMap = new Map<any, any>();

  const chartData = {
    labels: [
      "Red Team",
      "Blue Team",
      "Network",
      "Cloud",
      "Programming",
    ] as any,
    datasets: [
      {
        label: "# of Watched Content",
        data: [] as any,
        backgroundColor: "rgba(245, 31, 111, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };

  data.forEach((topic) => topicActivityMap.set(topic?._id, topic?.total));
  chartData.labels.forEach((label: string) =>
    {
      const data = topicActivityMap.get(label);
      chartData.datasets[0].data.push((data) ? data: 0)
    }
  );

  return chartData;
}

export default function TopicActivityChart() {
  const params = useParams();

  const [{ data, loading }] = useAxios({
    url: `users/${params.memberId}/topicActivity`,
  });

  if (loading) return <span className="loading loading-spinner"></span>;

  return (
    <Radar
      data={
        prepareChartData(
          data
        ) as any
      }
      options={{
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          r: {
            afterDataLimits: (axis) => axis.max += axis.max === 1 ? 1 : Math.floor(axis.max / 2),
            grid: {
              // color: "rgba(256, 256, 256, 0.1)",
            },
            
            ticks: {
              display: false,
            },
          },
        },
      }}
    />
  );
}
