import { UserGroupIcon } from "@heroicons/react/24/outline";
import useAxios from "axios-hooks";
import { useEffect } from "react";
import {
  Link,
  Outlet,
  useLocation,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { UserResDto } from "../../api/Api";

const tabs = [
  {
    label: "Stats",
    pathname: "stats",
  },
  {
    label: "Settings",
    pathname: "settings",
  },
];

export default function Member(props: {}) {
  const location = useLocation();
  const params = useParams();

  const { setTitleComponent } = useOutletContext() as any;

  const [{ data, loading, error }, getMembers] = useAxios<UserResDto>(
    `/users/${params.memberId}`
  );

  useEffect(() => {
    
    setTitleComponent(
      <div className="flex items-center justify-center gap-2">
        <UserGroupIcon className="h-8 w-8"></UserGroupIcon>
        <Link to={"/members"} className="hover:underline hover:text-primary">
          Members
        </Link>
        <div>/</div>
        {!loading ? data?.username : <h1>Loading</h1>}
      </div>
    );
  }, [loading]);

  return (
    <div>
      <div className="bg-base-200 border-t-[1px] border-base-100">
        <div
          role="tablist"
          className="tabs tabs-bordered w-[20%] tab-border-none"
        >
          {tabs.map((tab, i) => (
            <Link
              to={tab.pathname}
              role="tab"
              key={i}
              className={`tab ${
                tab.pathname === location.pathname.split("/")[3]
                  ? "tab-active"
                  : ""
              }`}
            >
              {tab.label}
            </Link>
          ))}
        </div>
      </div>
      <div className="p-5">
        <Outlet></Outlet>
      </div>
    </div>
  );
}
