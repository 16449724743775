import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function CountedRedirect(props: {
  text: string;
  seconds: number;
  path: string;
}) {
  const [counter, setCounter] = useState(props.seconds);
  const [intervalInstance, setIntervalInstance] = useState(undefined as any);

  const navigate = useNavigate();

  useEffect(() => {

    if(!intervalInstance) {
      const interval = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1);
      }, 1000);
      setIntervalInstance(interval);
    }

    if(counter === 0) {
      clearInterval(intervalInstance);
      navigate('/members');
    }
   
  }, [counter]);

  return (
    <div>
      {props.text}. You will be redirect on{" "}
      <span className="font-bold">{counter}</span>.
    </div>
  );
}
