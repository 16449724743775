import { useForm, SubmitHandler } from "react-hook-form";
import { AuthLink } from "./components/AuthLink";
import useAxios from "axios-hooks";
import { useLocation, useNavigate } from "react-router-dom";

type Inputs = {
  username: string;
  code: string;
  password: string;
};

export default function ForgotPasswordConfirm(props: {}) {
  const navigate = useNavigate();
  const { state } = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const [{ data, loading, error }, forgotPasswordConfirm] = useAxios(
    {
      url: "/auth/password/forgot/confirm",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      let response = await forgotPasswordConfirm({
        data: {
          ...data,
          accountType: "BUSINESS"
        },
      });
      if (response.status === 200) {
        navigate("/auth/login", {
          state: {
            confirmed: true,
            message: "Your password has been updated successfully. Please proceed to login again.",
          }
        });
      }
    } catch (error) {}
  };

  return (
    <div className="flex items-center justify-center flex-col h-full gap-4">
      <form className="flex flex-col w-1/2" onSubmit={handleSubmit(onSubmit)}>
        <label className="form-control w-full">
          <div className="label">
            <span className="label-text">Username</span>
          </div>
          <input
            disabled={loading}
            defaultValue="vemaca1870@mnsaf.com"
            {...register("username", {
              required: true,
            })}
            type="text"
            className="input input-bordered input-sm w-full"
          />
          <div className="label">
            {errors?.username?.type === "required" && (
              <span className="label-text-alt text-error">
                Username is required
              </span>
            )}
          </div>
          <div className="label">
            <span className="label-text">Code</span>
          </div>
          <input
            disabled={loading}
            {...register("code", {
              required: true,
            })}
            type="text"
            className="input input-bordered input-sm w-full"
          />
          <div className="label">
            {errors?.code?.type === "required" && (
              <span className="label-text-alt text-error">
                Code is required
              </span>
            )}
          </div>
          <div className="label">
            <span className="label-text">New Password</span>
          </div>
          <input
            disabled={loading}
            {...register("password", {
              required: true,
            })}
            type="text"
            className="input input-bordered input-sm w-full"
          />
          <div className="label">
            {errors?.password?.type === "required" && (
              <span className="label-text-alt text-error">
                New Password is required
              </span>
            )}
          </div>
        </label>
        <div className="pt-2 w-full">
          <button
            className="btn btn-sm w-full btn-primary"
            disabled={loading}
            type="submit"
          >
            Reset Password
          </button>
        </div>
        {error && (
          <div className="alert alert-error text-sm mt-6 text-center">
            {error?.response?.data?.message}
          </div>
        )}
        {loading && (
          <div className="flex items-center justify-center mt-6">
            <div className="loading loading-spinner "></div>
          </div>
        )}
        {state?.emailSent && (
          <div className="alert alert-success text-sm mt-6 text-center">
            The password reset code has been successfully sent to your email
            address. Kindly fill out the form above to proceed with resetting
            your password.
          </div>
        )}
      </form>

      <div className="flex flex-col gap-2 items-center">
        <AuthLink
          label="Sign Up"
          message="You dont have an account?"
          path="/auth/signup"
        ></AuthLink>
        <AuthLink
          label="Sign In"
          message="You already have an account?"
          path="/auth/login"
        ></AuthLink>
      </div>
    </div>
  );
}
